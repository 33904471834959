<template>
  <div v-if="model && show" class="input-parameter-range">
    <md-checkbox
      class="md-primary"
      v-model="model.range.enabled"
      :disabled="disabled"
    >
      {{
        $t(
          payload.isForPoint
            ? 'actions.overwriteRange'
            : 'actions.activateRange',
        )
      }}
    </md-checkbox>
    <div v-if="model.range.enabled" class="ranges">
      <div class="md-layout md-size-20">
        <div
          v-for="k in thresholds"
          :key="k"
          :class="['md-layout-item', 'range', k]"
        >
          <input
            v-if="k !== 'basis'"
            type="number"
            v-model.number="model.range[k]"
            class="threshold-input"
            :step="0.1"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Feature } from '@/commons'

export default {
  name: 'InputParameterRange',

  props: {
    value: Object,
    payload: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    model: null,
    addParameter: null,
    thresholds: ['lowest', 'low', 'basis', 'high', 'highest'],
  }),

  computed: {
    show() {
      return this.$auth.hasFeature(Feature.Range)
    },
  },

  watch: {
    model: {
      deep: true,
      handler(val) {
        this.$emit('input', val)
      },
    },
  },

  mounted() {
    this.model = {
      range: {
        enabled: false,
      },
      ...this.value,
    }
  },
}
</script>

<style lang="scss" scoped>
$green: #66bb6a;
$yellow: #ffca28;
$red: #ef5350;

$border-color: rgba(0, 0, 0, 0.8);

.input-parameter-range {
  .threshold-input {
    width: 64px;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
    padding: 5px 3px;
    text-align: center;
    font-size: 13px;
  }
  .ranges-header {
    padding-top: 3px;
    margin-bottom: 3px;
    margin-top: 22px;

    .title {
      margin-top: 8px;
      font-size: 16px;
    }
  }
  .space {
    height: 22px;
  }
  .ranges {
    border-bottom: 0;
    .md-caption {
      padding: 0 5px;
      text-align: center;
    }
    .range {
      text-align: center;
      padding: 4px 3px;
    }
    .min,
    .lowest,
    .low {
      text-align: right;
      border-right: 1px solid $border-color;
      input {
        position: relative;
        right: -36px;
        top: -8px;
      }
    }
    .high,
    .highest,
    .max {
      text-align: left;
      border-left: 1px solid $border-color;
      input {
        position: relative;
        left: -36px;
        top: -8px;
      }
    }
    .min,
    .max {
      border-bottom: 3px solid $border-color;
    }
    .lowest,
    .highest {
      border-bottom: 3px solid $red;
    }
    .low,
    .high {
      border-bottom: 3px solid $yellow;
    }
    .basis {
      border-bottom: 3px solid $green;
    }
  }
}
</style>

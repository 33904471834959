<template>
  <md-field>
    <label :for="name">{{ $t(`labels.${name}`) }}</label>

    <md-select
      :name="name"
      :id="name"
      v-model="selectedLanguage"
      md-dense
      :disabled="disabled"
    >
      <md-option
        v-for="(locale, index) in config.supportedLocales"
        :key="'locale' + index"
        :value="locale"
      >
        {{ $t('locales.' + locale) }}
      </md-option>
    </md-select>
  </md-field>
</template>

<script>
import config from '@/config'

export default {
  name: 'InputLanguage',

  props: {
    value: Object,
    name: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    config,
    model: null,
    selectedLanguage: null,
  }),

  async mounted() {
    this.model = { ...this.value }

    let initialValue = null
    if (this.model[this.name]) {
      initialValue = this.model[this.name]
    }

    this.selectedLanguage = initialValue
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.model = {
          ...this.model,
          ...this.value,
        }
      },
    },

    selectedLanguage() {
      this.model[this.name] = this.selectedLanguage
      this.$emit('input', this.model)
    },
  },
}
</script>

<script>
import { Line } from 'vue-chartjs'
import moment from 'moment'

export default {
  name: 'MeasurementsChart',
  extends: Line,
  props: {
    pointData: Object,
  },
  watch: {
    pointData: function () {
      this.render()
    },
  },
  methods: {
    render: function () {
      let d = this.pointData

      let labels = []
      let datasets = []

      const colors = {
        chlorineFree: '#ccc',
        chlorineCombined: '#777',
        chlorineTotal: '#333',
        ph: '#35baf2',
        temperature: 'red',
      }

      let prevLabel
      let thisLabel
      for (let i = 0; i < d.parameters.length; i++) {
        let parameter = d.parameters[i]
        let data = []

        // Only include measurements of this parameter if they are numeric
        if (
          typeof d.measurements[0].results[parameter.uniqueName].value !==
          'string'
        ) {
          for (let a = d.measurements.length - 1; a >= 0; a--) {
            let m = d.measurements[a]
            // Only include in chart if not flagged
            if (!m.flagged) {
              // Only create the label array once
              if (i === 0) {
                prevLabel = thisLabel
                thisLabel = moment(m.createdAt).format('DD.MM')
                labels.push(prevLabel === thisLabel ? '' : thisLabel)
              }
              data.push(m.results[parameter.uniqueName].value)
            }
          }

          datasets.push({
            label: parameter.title + ' (' + parameter.unit + ')',
            borderColor: colors[parameter.type] || '#000',
            lineTension: 0,
            data: data,
            fill: false,
          })
        }
      }

      const dataCollection = {
        labels: labels,
        datasets: datasets,
      }

      this.renderChart(dataCollection, {
        responsive: true,
        maintainAspectRatio: false,
      })
    },
  },
  mounted() {
    this.render()
  },
}
</script>

import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'

Vue.use(Vuex)

export const defaultAuthState = {
  localizedMainConfigs: null,
  loggedIn: false,
  sessionExpiresAt: null,
  user: null,
}

const store = new Vuex.Store({
  state: {
    showSnackbar: false,
    loading: false,
    showQuickNav: false,
    breadcrumbs: [],
    alerts: [],
    entity: {},
    parameters: [],
    auth: defaultAuthState,
  },
  getters: {
    session(state) {
      return state.session
    },
    auth(state) {
      return state.auth
    },
  },
  mutations: {
    setLoading(state, bool) {
      state.loading = bool
    },
    setShowError(state, message) {
      state.showSnackbar = true
      state.snackbarMessage = message
    },
    setShowMessage(state, message) {
      state.showSnackbar = true
      state.snackbarMessage = message
    },
    pushBreadcrumb(state, breadcrumb) {
      // state.showQuickNav = true
      let breadcrumbs = _.slice(state.breadcrumbs, 0, breadcrumb.position - 1)
      breadcrumbs.push(breadcrumb)
      state.breadcrumbs = breadcrumbs
    },
    showQuickNav(state, bool) {
      state.showQuickNav = bool
    },
    setAlerts(state, data) {
      state.alerts = data
    },
    setEntity(state, data) {
      state.entity = data
    },
    setParameters(state, data) {
      state.parameters = data
    },
    setAuth(state, data) {
      state.auth = data
    },
  },
  actions: {
    loading({ commit }) {
      commit('setLoading', true)
    },
    error({ commit }, message) {
      commit('setLoading', false)
      commit(
        'setShowError',
        message || 'Fehler: Daten konnten nicht geladen werden.',
      )
    },
    message({ commit }, message = '') {
      commit('setShowMessage', message)
    },
    success({ commit }) {
      commit('setLoading', false)
    },
    pushBreadcrumb({ commit }, breadcrumb) {
      commit('pushBreadcrumb', breadcrumb)
    },
    showQuickNav({ commit }, bool) {
      commit('showQuickNav', bool)
    },
    setAlerts({ commit }, data) {
      commit('setAlerts', data)
    },
    setEntity({ commit }, data) {
      commit('setEntity', data)
    },
    setParameters({ commit }, data) {
      commit('setParameters', data)
    },
    setAuth({ commit }, data) {
      commit('setAuth', data)
    },
  },
})

export default store

<template>
  <div class="app-list-status" v-if="show">
    <span :class="classObject">
      <md-icon v-if="status === 2">warning</md-icon>
      <md-icon v-else-if="status === 3">error</md-icon>
      <md-icon v-else>check</md-icon>
      <md-tooltip>{{ getTooltip(status) }}</md-tooltip>
    </span>
  </div>
</template>

<script>
import { Feature } from '@/commons'

export default {
  name: 'AppListStatus',
  props: {
    status: Number,
  },
  computed: {
    classObject: function () {
      return {
        status: true,
        'status-green': this.status === 1 || this.status === 0,
        'status-yellow': this.status === 2,
        'status-red': this.status === 3,
      }
    },
    show() {
      return this.$auth.hasFeature(Feature.Range)
    },
  },
  methods: {
    getTooltip(status) {
      let t = 'Status: '
      switch (status) {
        case 1:
          t += 'OK'
          break
        case 2:
          t += 'Warnung'
          break
        case 3:
          t += 'Kritisch'
          break
      }
      return t
    },
  },
}
</script>

<style lang="scss" scoped>
$green: #66bb6a;
$yellow: #ffca28;
$red: #ef5350;

.app-list-status {
  .status {
    display: inline-block;
    width: 40px;
    padding: 3px 5px;
    text-align: center;
    color: white;
    border-radius: 14px;

    .md-icon.md-theme-content.md-icon-font {
      color: white;
      font-size: 10px;
    }
  }
  .status-green {
    background: $green;
  }
  .status-yellow {
    background: $yellow;
    color: black;
  }
  .status-red {
    background: $red;
  }
}
</style>

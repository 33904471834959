<template>
  <div>
    <treeselect
      :multiple="true"
      :value="value"
      :options="options"
      :placeholder="placeholder"
      :load-options="loadChildren"
      :default-expand-level="1"
      value-consists-of="LEAF_PRIORITY"
      @input="onTreeselectInput"
    />
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import api from '@/api'

// TODO: fix this together with export page!

export default {
  name: 'InputPoints',

  components: { Treeselect },

  props: {
    value: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    options: [],
  }),

  computed: {
    placeholder: function () {
      return this.$t('actions.select')
    },
  },

  async mounted() {
    let opts = []
    const [err, entities] = await api.get('entities').send()
    if (err) return
    entities.map((e) => {
      opts.push({
        id: e._id,
        label: e.title,
        children: null,
      })
    })
    this.options = opts
  },

  methods: {
    onTreeselectInput(val) {
      this.$emit('change', val)
    },

    async loadChildren({ parentNode, callback }) {
      const [err, places] = await api
        .get('places', { query: { entity: parentNode.id } })
        .send()
      if (err) return
      const opts = []
      places.map((place) => {
        const children = []
        place.points.map((point) => {
          children.push({
            id: point._id,
            label: point.title,
          })
        })
        opts.push({
          id: place.id,
          label: place.title,
          children: children,
        })
      })
      parentNode.children = opts
      callback()
    },
  },
}
</script>

<template>
  <div class="padding-top">
    <AppList :opts="opts" :items="items">
      <template v-slot:header>
        <div class="md-layout">
          <div class="md-layout-item">
            <div class="header">
              <h1 class="md-title">{{ $t('navs.usages') }}</h1>
            </div>
          </div>
          <div class="md-layout-item">
            <div class="export-column ta-r mt-1">
              <md-button class="md-primary" @click="exportData">{{
                $t('actions.exportXlsx')
              }}</md-button>
            </div>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-xsmall-size-100 md-small-size-50">
            <md-field>
              <label for="range">{{ $t('labels.range') }}</label>
              <md-select v-model="range" name="range" id="range">
                <md-option value="currentMonth">{{
                  $t('labels.currentMonth')
                }}</md-option>
                <md-option value="lastMonth">{{
                  $t('labels.lastMonth')
                }}</md-option>
                <md-option value="daterange">{{
                  $t('labels.customDateRange')
                }}</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-xsmall-size-100 md-small-size-50">
            <md-datepicker
              v-if="range === 'daterange'"
              v-model="dateStart"
              :md-immediately="true"
              :md-close-on-blur="false"
            >
              <label>{{ $t('labels.from') }}</label>
            </md-datepicker>
          </div>
          <div class="md-layout-item md-xsmall-size-100 md-small-size-50">
            <md-datepicker
              v-if="range === 'daterange'"
              v-model="dateEnd"
              :md-immediately="true"
              :md-close-on-blur="false"
            >
              <label>{{ $t('labels.to') }}</label>
            </md-datepicker>
          </div>
        </div>
      </template>
    </AppList>
  </div>
</template>

<script>
import moment from 'moment'
import XLSX from 'xlsx'
import api from '@/api'

const getFilter = (vm = {}) => {
  // Default is 'currentMonth'
  let dateStart = moment().startOf('month').format()
  let dateEnd = moment().format()
  if (vm.range === 'daterange' && vm.dateStart && vm.dateEnd) {
    dateStart = moment(vm.dateStart).startOf('day').format()
    dateEnd = moment(vm.dateEnd).endOf('day').format()
  } else if (vm.range === 'lastMonth') {
    let lastMonth = moment().subtract(1, 'months')
    dateStart = lastMonth.startOf('month').format()
    dateEnd = lastMonth.endOf('month').format()
  }
  return { dateStart, dateEnd }
}

export default {
  name: 'PageUsages',

  data: () => ({
    range: 'currentMonth',
    dateStart: moment().startOf('month').toDate(),
    dateEnd: moment().toDate(),
    items: [],
    opts: {
      columns: [
        {
          name: 'client.title',
          label: 'labels.client',
        },
        {
          name: 'numLilianValuesMeasured',
        },
        {
          name: 'numManualValuesMeasured',
        },
        {
          name: 'numLilianValuesExported',
        },
        {
          name: 'numManualValuesExported',
        },
        {
          name: 'numLilianValues',
        },
        {
          name: 'numManualValues',
        },
        {
          name: 'total',
        },
      ],
    },
  }),

  mounted() {
    this.fetchData()
  },

  watch: {
    range: function () {
      this.updateUsages()
    },

    dateStart: function () {
      this.updateUsages()
    },

    dateEnd: function () {
      this.updateUsages()
    },
  },

  methods: {
    exportData() {
      let vm = this
      let filter = getFilter(vm)
      let filename =
        'Verbräuche__' +
        moment(filter.dateStart).format('YYYY-MM-DD') +
        ' - ' +
        moment(filter.dateEnd).format('YYYY-MM-DD') +
        '.xlsx'
      let header = [
        'Betreiber',
        'LILIAN gem.',
        'Manuell gem.',
        'LILIAN exp.',
        'Manuell exp.',
        'LILIAN',
        'Manuell',
        'Gesamt',
      ]
      let data = []
      this.items.map((u) => {
        // TODO: this should be handled by the API
        const title = u.client ? u.client.title : 'Gelöschter Betreiber'
        let row = {
          Betreiber: title,
          LILIAN: u.numLilianValues,
          Manuell: u.numManualValues,
          'LILIAN gem.': u.numLilianValuesMeasured,
          'Manuell gem.': u.numManualValuesMeasured,
          'LILIAN exp.': u.numLilianValuesExported,
          'Manuell exp.': u.numManualValuesExported,
          Gesamt: u.total,
        }
        data.push(row)
      })
      var worksheet = XLSX.utils.json_to_sheet(data, { header: header })
      var workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Verbräuche')
      XLSX.writeFile(workbook, filename)
    },

    updateUsages() {
      this.fetchData(getFilter(this))
    },

    async fetchData(filter = {}) {
      const [err, usages] = await api.get('usages', { query: filter }).send()
      if (err) return
      this.items = usages
    },
  },
}
</script>

<template>
  <div v-if="model" class="">
    <div class="md-size-50">
      <md-checkbox
        class="md-primary"
        v-model="model.offset.enabled"
        :disabled="disabled"
      >
        {{
          $t(
            payload.isForPoint
              ? 'actions.overwriteOffset'
              : 'actions.activateOffset',
          )
        }}
      </md-checkbox>
    </div>
    <div v-if="model.offset.enabled" style="width: 240px; margin-top: -12px">
      <md-field>
        <label>{{ $t('labels.offset') }}</label>
        <md-input
          v-model.number="model.offset.value"
          type="number"
          :disabled="disabled"
          :step="0.01"
          @keyup.native="sanitizeOffsetValue"
          :min="payload.offsetMin"
          :max="payload.offsetMax"
        />
      </md-field>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputParameterOffset',

  props: {
    value: Object,
    payload: {
      type: Object,
      default: () => ({
        isForPoint: false,
        offsetMin: undefined,
        offsetMax: undefined,
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    model: null,
  }),

  watch: {
    model: {
      deep: true,
      handler(val) {
        this.$emit('input', val)
      },
    },
  },

  mounted() {
    const offset = {
      enabled: false,
      value: 0,
      ...this.value.offset,
    }

    this.model = {
      ...this.value,
      offset,
    }
  },

  methods: {
    sanitizeOffsetValue() {
      const { offsetMin, offsetMax } = this.payload

      let val = this.model.offset.value

      if (!val) return

      if (typeof offsetMax === 'number') {
        val = Math.min(offsetMax, val)
      }

      if (typeof offsetMin === 'number') {
        val = Math.max(offsetMin, val)
      }

      this.model.offset.value = val
    },
  },
}
</script>

<template>
  <div v-if="show">
    <label>API Key</label>
    <div v-if="loading">Please wait...</div>
    <div v-else>
      <div v-if="hasKey">
        {{ key }}
      </div>
      <md-button v-else>Create API Key</md-button>
    </div>
  </div>
</template>

<script>
import api from '@/api'

// TODO: finish and add this functionality

export default {
  name: 'InputClientToken',
  props: {
    clientId: String,
  },
  data: () => ({
    key: null,
    hasKey: false,
  }),
  computed: {
    show() {
      return true
    },
  },
  mounted() {
    // if (!this.show) return
    api
      .call('getTokenMe', {
        data: {
          clientId: this.clientId,
        },
      })
      .then((token) => {
        if (token.key) {
          this.key = token.key
          this.hasKey = true
        } else {
          this.hasKey = false
        }
      })
  },
  methods: {},
}
</script>

<template>
  <div class="entities">
    <md-empty-state
      v-if="items && items.length === 0"
      md-icon="help_outline"
      :md-label="$t('states.noResults')"
    >
    </md-empty-state>

    <div class="md-layout md-gutter md-alignment-left">
      <div
        v-for="(item, index) in items"
        md-with-hover
        :key="index"
        class="md-layout-item md-size-25 md-medium-size-33 md-small-size-50 md-xsmall-size-100"
      >
        <md-card md-with-hover>
          <md-ripple>
            <div @click="openEntitiy(item)">
              <md-card-area>
                <md-card-media>
                  <img
                    class="water"
                    :src="getEntityImageForIndex(index)"
                    alt="water"
                  />
                </md-card-media>

                <md-card-header>
                  <div class="md-body-2 uc mb-1">{{ item.title }}</div>
                  <div class="md-subhead">{{ item.address }}</div>
                </md-card-header>
              </md-card-area>

              <md-card-actions>
                <md-button
                  class="md-icon-button md-primary"
                  v-on:click.stop="openForm(item)"
                >
                  <md-icon>edit</md-icon>
                </md-button>
              </md-card-actions>
            </div>
          </md-ripple>

          <div class="status-wrapper">
            <AppListStatus :status="item.status" />
          </div>
        </md-card>
      </div>
    </div>

    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
      <AppFormDialog
        :opts="formOpts"
        :values="formValues"
        :defValues="formDefValues"
        @close="showDialog = false"
        @success="formSuccess"
      />
    </md-dialog>

    <md-snackbar
      :md-active.sync="showSnackbar"
      :md-duration="2000"
      md-persistent
    >
      <span>Änderungen wurden erfolgreich gespeichert!</span>
      <md-button class="md-primary" @click="showSnackbar = false"
        >Schließen</md-button
      >
    </md-snackbar>

    <md-button
      class="md-fab md-fab-bottom-right md-fixed md-accent"
      @click="openCreateForm"
    >
      <md-icon>add</md-icon>
      <md-tooltip md-direction="top">Neu</md-tooltip>
    </md-button>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import api from '@/api'

let handleApiData = (vm, data) => {
  vm.items = data
  vm.$store.dispatch('pushBreadcrumb', {
    position: 1,
    title: vm.$t('navs.entities'),
    to: { name: 'entities' },
  })
}

export default {
  name: 'PageEntities',

  data: () => ({
    opts: {
      titleKey: 'navs.entities',
      createTextKey: 'actions.createEntity',
      routeName: 'places',
      editRouteName: 'entity',
      searchName: 'title',
      columns: [
        {
          name: 'title',
        },
        {
          name: 'description',
        },
        {
          name: 'status',
          type: 'status',
        },
      ],
    },
    formOpts: {
      titleKey: 'labels.entity',
      endpoint: 'entities',
      inputs: {
        title: {
          type: 'text',
          validation: {
            required,
          },
        },
        address: {
          type: 'text',
          validation: {
            required,
          },
        },
        description: {
          type: 'textarea',
        },
      },
      rows: [['title'], ['address'], ['description']],
    },
    items: null,
    isLoading: false,
    showDialog: false,
    showSnackbar: false,
    formValues: {},
    formDefValues: {},
  }),

  methods: {
    openEntitiy: function (item) {
      this.$router.push({ name: 'entity', params: { id: item._id } })
    },

    openForm: function (item) {
      this.formOpts.isCreate = false
      this.formValues = item
      this.showDialog = true
    },

    openCreateForm: function () {
      this.formOpts.isCreate = true
      this.formValues = {}
      this.showDialog = true
    },

    formSuccess() {
      this.showSnackbar = true
      this.fetchData()
    },

    async fetchData() {
      const [err, data] = await api.get('entities').send()
      if (err) return
      handleApiData(this, data)
    },

    getEntityImageForIndex(index) {
      const maxNumber = 11
      const current = index + 1
      const substract = maxNumber * Math.floor(current / maxNumber)
      const number = current - substract

      return '../static/water/water-' + number + '.jpg'
    },
  },

  created() {
    this.formOpts.defValues = {
      status: 1,
    }
  },

  mounted() {
    this.fetchData()
  },
}
</script>

<style lang="scss" scoped>
.entities {
  .md-card {
    margin-bottom: 40px;
    width: 100%;
    position: relative;
  }

  .md-card-content {
    height: 120px;
  }

  .status-wrapper {
    position: absolute;
    bottom: 12px;
    left: 16px;
  }

  .water {
    height: 200px;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"body-1"},[_vm._v(_vm._s(_vm.$t('labels.pointParameters')))]),(_vm.loading)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('states.loading'))+" ")]):(_vm.pointParameterConfigs && _vm.parameters)?_c('div',[(_vm.noParameterExist)?_c('div',{staticClass:"muted"},[_vm._v(" - "+_vm._s(_vm.$t('states.noResults'))+" - ")]):_vm._e(),_vm._l((_vm.parameterList),function(parameter){return _c('div',{key:parameter.uniqueName},[_c('md-switch',{staticClass:"md-primary",attrs:{"disabled":parameter.disabled || !parameter.requirementsAvailable},model:{value:(_vm.pointParameterConfigs[parameter.uniqueName].enabled),callback:function ($$v) {_vm.$set(_vm.pointParameterConfigs[parameter.uniqueName], "enabled", $$v)},expression:"pointParameterConfigs[parameter.uniqueName].enabled"}},[_c('span',{staticClass:"bold"},[_vm._v(_vm._s(parameter.title))])]),(!parameter.requirementsAvailable)?_c('div',{staticClass:"ml-4 mb-2"},[_c('span',[_c('md-icon',[_vm._v("info")]),_vm._v(" "+_vm._s(_vm.$t('labels.requiredParameters'))+": "+_vm._s(parameter.calculationRequirementTitles.join(', '))+" ")],1)]):_vm._e(),(_vm.pointParameterConfigs[parameter.uniqueName].enabled)?_c('div',{staticClass:"ml-4 mr-2"},[(
            _vm.parameterTypesIndex[parameter.type].configs.offsetEnabled &&
            _vm.pointParameterConfigs[parameter.type]
          )?_c('div',{staticClass:"mb-1"},[_c('InputParameterOffset',{attrs:{"payload":{
              ..._vm.payload,
              ..._vm.parameterTypesIndex[parameter.type].configs,
            }},model:{value:(_vm.pointParameterConfigs[parameter.uniqueName]),callback:function ($$v) {_vm.$set(_vm.pointParameterConfigs, parameter.uniqueName, $$v)},expression:"pointParameterConfigs[parameter.uniqueName]"}})],1):_vm._e(),(_vm.parameterTypesIndex[parameter.type].configs.rangeEnabled)?_c('div',{staticClass:"mb-2"},[_c('InputParameterRange',{attrs:{"payload":_vm.payload},model:{value:(_vm.pointParameterConfigs[parameter.uniqueName]),callback:function ($$v) {_vm.$set(_vm.pointParameterConfigs, parameter.uniqueName, $$v)},expression:"pointParameterConfigs[parameter.uniqueName]"}})],1):_vm._e()]):_vm._e(),_c('md-divider')],1)})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
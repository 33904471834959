<template>
  <div class="app-login">
    <div class="md-layout">
      <div class="md-layout-item"></div>
      <div
        class="md-layout-item md-xsmall-size-90 md-small-size-70 md-medium-size-50 md-large-size-40 md-xlarge-size-30"
      >
        <md-card>
          <md-content md-theme="toolbar" class="md-accent">
            <md-card-header>
              <div class="image-wrapper mb-3">
                <img src="../assets/logo-white-full.png" />
              </div>
            </md-card-header>
          </md-content>

          <div style="height: 20px">
            <md-progress-bar
              class="md-primary loading"
              md-mode="indeterminate"
              v-if="sending"
            />
          </div>

          <md-card-content>
            <div class="md-display-1 mb-4">Lilian Manager</div>

            <!-- Password Reset Form -->
            <div class="form-wrapper" v-if="currentForm === 'RESET'">
              <h2 class="md-body-1">
                {{ $t('actions.resetPassword') }}
              </h2>
              <md-field md-clearable :class="{ 'md-invalid': error }">
                <label>{{ $t('labels.email') }}</label>
                <md-input
                  v-model="formValues.email"
                  @keyup="sanitizeEmail"
                  @input="resetError"
                  :disabled="disabled"
                />
              </md-field>

              <md-card-actions class="mb-2">
                <md-button @click="currentForm = 'LOGIN'">
                  {{ $t('actions.cancel') }}
                </md-button>
                <md-button
                  md-theme="content"
                  type="submit"
                  class="md-primary"
                  @click="resetPassword"
                >
                  {{ $t('actions.resetPassword') }}
                </md-button>
              </md-card-actions>
            </div>

            <div v-else class="form-wrapper">
              <form @submit.prevent="login">
                <!-- Login Form -->
                <template v-if="currentForm === 'LOGIN'">
                  <h2 class="md-body-1">
                    {{ $t('misc.loginWithEmailAndPassword') }}
                  </h2>
                  <md-field md-clearable :class="{ 'md-invalid': error }">
                    <label>{{ $t('labels.email') }}</label>
                    <md-input
                      v-model="formValues.email"
                      @keyup="sanitizeEmail"
                      @input="resetError"
                      :disabled="disabled"
                    />
                  </md-field>

                  <md-field :class="{ 'md-invalid': error }">
                    <label>{{ $t('labels.password') }}</label>
                    <md-input
                      v-model="formValues.password"
                      type="password"
                      @keyup="sanitizePassword"
                      @input="resetError"
                      :disabled="disabled"
                    />
                    <div v-if="error" class="md-error">
                      {{ $t('states.loginFailed') }}
                    </div>
                  </md-field>
                </template>

                <!-- Multi-Factor Authentification Form -->
                <template v-else>
                  <div class="mb-3">
                    <h2>{{ $t('labels.verifyLogin') }}</h2>
                    <p>
                      {{ $t('misc.verificationCodeSent') }}
                    </p>
                    <a
                      type="link"
                      @click="onResendMultiFactorAuthCodeButtonClick"
                      href="#"
                    >
                      {{ $t('actions.resendCode') }}
                    </a>
                  </div>
                  <md-field :class="{ 'md-invalid': error }">
                    <label>{{ $t('labels.code') }}</label>
                    <md-input
                      v-model="formValues.multiFactorAuthCode"
                      maxlength="6"
                      @keyup.native="onMultiFactorAuthCodeKeyup"
                      @input="resetError"
                      :disabled="disabled"
                    />
                    <div v-if="error" class="md-error">
                      {{ $t('states.loginFailed') }}
                    </div>
                  </md-field>
                </template>

                <md-card-actions class="mb-2">
                  <md-button
                    v-if="currentForm === 'LOGIN'"
                    @click="currentForm = 'RESET'"
                  >
                    {{ $t('actions.resetPassword') }}
                  </md-button>
                  <md-button
                    v-else-if="currentForm === 'MFA'"
                    @click="currentForm = 'LOGIN'"
                  >
                    {{ $t('actions.cancel') }}
                  </md-button>
                  <md-button
                    md-theme="content"
                    type="submit"
                    class="md-primary"
                  >
                    {{ $t('actions.login') }}
                  </md-button>
                </md-card-actions>
              </form>
            </div>
          </md-card-content>
        </md-card>

        <div></div>
      </div>
      <div class="md-layout-item"></div>
    </div>

    <md-dialog :md-active.sync="showTermsDialog" @md-closed="cancelTermsDialog">
      <md-dialog-title>{{ $t('navs.privacyPolicy') }}</md-dialog-title>
      <md-dialog-content>
        <p>{{ $t('misc.acceptPrivacyPolicyToContinue') }}</p>
        <iframe
          src="https://lilianlabs.com/lilian-datenschutzerklaerung"
        ></iframe>
        <md-checkbox v-model="formValues.termsAccepted">
          {{ $t('labels.termsAccepted') }}
        </md-checkbox>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button @click="cancelTermsDialog">
          {{ $t('actions.cancel') }}
        </md-button>
        <md-button class="md-primary" @click="confirmTermsDialog">
          {{ $t('actions.ok') }}
        </md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import auth from '@/auth'
import api from '@/api'

const createFormValueDefaults = () => ({
  email: '',
  password: '',
  termsAccepted: false,
  multiFactorAuthCode: null,
})

export default {
  name: 'AppLogin',

  data: () => ({
    formValues: createFormValueDefaults(),
    passwordResetEmail: null,
    error: false,
    sending: false,
    disabled: false,
    showTermsDialog: false,
    currentForm: 'LOGIN',
    showMultiFactorAuthForm: false,
  }),

  methods: {
    async login() {
      let vm = this
      vm.sending = true
      vm.disabled = true

      const res = await auth.login(this.formValues)

      if (res) {
        // Login request was performed without HTTP errors.
        vm.sending = false
        vm.disabled = false

        if (!res.success) {
          // Credentials were correct but user action is requried before login can
          // be completed.
          if (res.userLanguage) {
            // The API returned the language of the user. We update the CMS accordingly.
            await auth.setLocaleAsync(res.userLanguage)
          }

          if (res.termsNotAccepted) {
            // Login failed due to missing terms confirmation.
            // Show accept terms dialog.
            this.showTermsDialog = true
          } else if (res.multiFactorAuthCodeRequired) {
            // User needs to enter a verification code.
            this.currentForm = 'MFA'
          } else {
            // Login failed due to different error.
            this.error = true
          }
        } else {
          // All good.
          this.$router.replace({ name: 'entities' })
        }
      } else {
        // Error.
        vm.disabled = false
        vm.sending = false
      }
    },

    async resetPassword() {
      this.sending = true
      this.disabled = true

      await api
        .post('auth/reset-password', {
          body: { email: this.formValues.email },
        })
        .send()

      this.resetFormValues()
      this.sending = false
      this.disabled = false
      this.currentForm = 'LOGIN'

      this.$store.dispatch('message', this.$t('states.passwordReset'))
    },

    confirmTermsDialog() {
      if (this.formValues.termsAccepted) {
        // If the user has accepted the terms we repeat the login
        this.login()
      } else {
        // If not, we cancel
        this.cancelTermsDialog()
      }
    },

    cancelTermsDialog() {
      // Reset accept checkbox
      this.formValues.termsAccepted = false
      // Close dialog
      this.showTermsDialog = false
    },

    resetError() {
      this.error = false
    },

    resetFormValues() {
      this.formValues = createFormValueDefaults()
    },

    onMultiFactorAuthCodeKeyup() {
      let code = this.formValues.multiFactorAuthCode
      this.formValues.multiFactorAuthCode = code.replace(/\D/g, '').substr(0, 6)
    },

    async onResendMultiFactorAuthCodeButtonClick() {
      // Reposting the login without a auth code will trigger a new code
      // being generated and email being sent.
      const data = this.formValues

      delete data.multiFactorAuthCode

      await auth.login(data)
    },

    sanitizePassword() {
      // Remove white spaces.
      this.formValues.password = this.formValues.password.replace(/\s/g, '')
    },

    sanitizeEmail() {
      // Remove white spaces.
      this.formValues.email = this.formValues.email.replace(/\s/g, '')
    },
  },
}
</script>

<style lang="scss">
iframe {
  height: 400px !important;
  border: 1px solid #ccc;
  width: 100%;
}

.app-login {
  padding-top: 60px;

  .md-card-header {
    margin: 0;
  }

  .image-wrapper {
    margin-top: 20px;
    img {
      height: 64px;
    }
  }

  .form-wrapper {
    position: relative;

    .loading {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
    }
  }
}
</style>

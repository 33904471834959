<template>
  <div>
    <div class="padding-top">
      <md-card>
        <md-card-header>
          <div class="header">
            <h1 class="md-title">{{ $t('navs.export') }}</h1>
            <h2 class="md-caption subtitle">
              {{ $t('misc.exportAllValuesForASpecificDateRange') }}
            </h2>
          </div>
        </md-card-header>

        <md-divider />

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-xsmall-size-100 md-small-size-50">
              <md-field>
                <label for="range">{{ $t('labels.range') }}</label>
                <md-select v-model="range" name="range" id="range">
                  <md-option value="currentMonth">{{
                    $t('labels.currentMonth')
                  }}</md-option>
                  <md-option value="lastMonth">{{
                    $t('labels.lastMonth')
                  }}</md-option>
                  <md-option value="daterange">{{
                    $t('labels.customDateRange')
                  }}</md-option>
                </md-select>
              </md-field>
            </div>
            <div class="md-layout-item md-xsmall-size-100 md-small-size-50">
              <md-datepicker
                v-if="range === 'daterange'"
                v-model="dateStart"
                :md-immediately="true"
                :md-close-on-blur="false"
              >
                <label>{{ $t('labels.from') }}</label>
              </md-datepicker>
            </div>
            <div class="md-layout-item md-xsmall-size-100 md-small-size-50">
              <md-datepicker
                v-if="range === 'daterange'"
                v-model="dateEnd"
                :md-immediately="true"
                :md-close-on-blur="false"
              >
                <label>{{ $t('labels.to') }}</label>
              </md-datepicker>
            </div>
          </div>

          <div class="uc mb-1 muted text-smaller">{{ $t('navs.points') }}</div>
          <InputPoints :value="points" @change="onPointsChange" />
        </md-card-content>

        <md-divider />

        <md-card-actions>
          <div class="mt-1 mb-1 ta-r">
            <md-button class="md-primary" @click="exportXLSX">{{
              $t('actions.exportXlsx')
            }}</md-button>
          </div>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { ExportHelper } from '@/helpers'

const getFilter = (vm = {}) => {
  // Default is 'currentMonth'
  let dateStart = moment().startOf('month').format()
  let dateEnd = moment().format()
  if (vm.range === 'daterange' && vm.dateStart && vm.dateEnd) {
    dateStart = moment(vm.dateStart).startOf('day').format()
    dateEnd = moment(vm.dateEnd).endOf('day').format()
  } else if (vm.range === 'lastMonth') {
    let lastMonth = moment().subtract(1, 'months')
    dateStart = lastMonth.startOf('month').format()
    dateEnd = lastMonth.endOf('month').format()
  }
  return { dateStart, dateEnd }
}

export default {
  name: 'PageExport',

  data: () => ({
    range: 'currentMonth',
    dateStart: moment().startOf('month').toDate(),
    dateEnd: moment().toDate(),
    points: [],
  }),

  methods: {
    onPointsChange(points) {
      this.points = points
    },

    exportXLSX() {
      const points = this.points
      const filter = getFilter(this)
      const dateStart = filter.dateStart
      const dateEnd = filter.dateEnd

      ExportHelper.createMeasurementsExport({
        dateStart,
        dateEnd,
        points,
      })
    },
  },
}
</script>

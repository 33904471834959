<template>
  <md-button @click="changeClient" class="md-accent">
    {{ $t('actions.switchDashboard') }}
  </md-button>
</template>

<script>
import auth from '@/auth'

export default {
  name: 'AppListSwitchClient',

  props: {
    item: Object,
  },

  methods: {
    changeClient() {
      let item = this.item
      auth.switchClient(item._id, () => {
        this.$router.push({ name: 'entities' })
      })
    },
  },
}
</script>

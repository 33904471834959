<template>
  <AppLoading v-if="!initDone" />
  <div v-else id="app">
    <md-app class="app" md-mode="fixed">
      <template v-if="loggedIn">
        <md-app-toolbar md-elevation="2" md-theme="toolbar" class="md-primary">
          <md-button class="md-icon-button md-accent" @click="toggleDrawer">
            <md-icon>menu</md-icon>
          </md-button>

          <router-link class="md-accent" :to="{ name: 'entities' }">
            <img class="logo-toolbar ml-3" src="./assets/logo-white.png" />
          </router-link>

          <div class="md-toolbar-section-end">
            <!-- Alerts Indicator -->
            <md-badge
              v-if="$store.state.alerts.length > 0"
              md-theme="drawer"
              class="md-primary mr-3"
              :md-content="$store.state.alerts.length"
            >
              <md-button
                class="md-icon-button"
                @click="$router.push({ name: 'alerts' })"
              >
                <md-icon>warning</md-icon>
              </md-button>
            </md-badge>

            <!-- Client Title -->
            <div class="md-body-2 mr-2 md-xsmall-hide">
              {{ $store.state.auth.user.client.title }}
            </div>

            <!-- Admin Dropdown -->
            <md-menu md-size="small" v-if="isSuperadmin">
              <md-button class="md-icon-button" md-menu-trigger>
                <md-icon>settings</md-icon>
              </md-button>
              <md-menu-content>
                <md-subheader>Admin</md-subheader>
                <md-divider />
                <md-menu-item @click="$router.push({ name: 'superadmins' })">
                  <md-icon>manage_accounts</md-icon>
                  <span class="md-list-item-text">
                    {{ $t('navs.superadmins') }}
                  </span>
                </md-menu-item>
                <md-menu-item @click="$router.push({ name: 'clients' })">
                  <md-icon>business</md-icon>
                  <span class="md-list-item-text">
                    {{ $t('navs.clientManagement') }}
                  </span>
                </md-menu-item>
                <md-menu-item @click="$router.push({ name: 'resellers' })">
                  <md-icon>currency_exchange</md-icon>
                  <span class="md-list-item-text">
                    {{ $t('navs.resellerManagement') }}
                  </span>
                </md-menu-item>
                <md-menu-item @click="$router.push({ name: 'usages' })">
                  <md-icon>bar_chart</md-icon>
                  <span class="md-list-item-text">{{ $t('navs.usages') }}</span>
                </md-menu-item>
              </md-menu-content>
            </md-menu>

            <!-- Reseller Clients Button -->
            <md-button
              v-if="isReseller"
              class="md-icon-button md-accent"
              @click="$router.push({ name: 'clients' })"
            >
              <md-icon>settings</md-icon>
            </md-button>

            <!-- Account Dropdown -->
            <md-menu md-size="small">
              <md-button class="md-icon-button" md-menu-trigger>
                <md-icon>account_circle</md-icon>
              </md-button>
              <md-menu-content>
                <md-subheader>{{ $store.state.auth.user.email }}</md-subheader>
                <md-divider />
                <md-menu-item @click="openAccountSettingsForm">
                  <md-icon>account_circle</md-icon>
                  <span class="md-list-item-text">
                    {{ $t('navs.accountSettings') }}
                  </span>
                </md-menu-item>
                <md-menu-item @click="logout">
                  <md-icon>exit_to_app</md-icon>
                  <span class="md-list-item-text">
                    {{ $t('actions.logout') }}
                  </span>
                </md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </md-app-toolbar>

        <!-- Drawer -->
        <md-app-drawer :md-active.sync="showDrawer" md-theme="drawer">
          <md-content>
            <div class="logo">
              <img src="./assets/logo-white-full.png" />
            </div>
          </md-content>

          <md-list>
            <md-subheader>{{ $t('navs.subheadings.management') }}</md-subheader>

            <router-link :to="{ name: 'entities' }">
              <md-list-item @click="toggleDrawer">
                <md-icon>store</md-icon>
                <span class="md-list-item-text">{{ $t('navs.entities') }}</span>
              </md-list-item>
            </router-link>

            <router-link :to="{ name: 'measurements' }">
              <md-list-item @click="toggleDrawer">
                <md-icon>fact_check</md-icon>
                <span class="md-list-item-text">
                  {{ $t('navs.measurements') }}
                </span>
              </md-list-item>
            </router-link>

            <router-link :to="{ name: 'alerts' }">
              <md-list-item @click="toggleDrawer">
                <md-icon>warning</md-icon>
                <span class="md-list-item-text">{{ $t('navs.alerts') }}</span>
              </md-list-item>
            </router-link>

            <router-link :to="{ name: 'export' }">
              <md-list-item @click="toggleDrawer">
                <md-icon>cloud_download</md-icon>
                <span class="md-list-item-text">{{ $t('navs.export') }}</span>
              </md-list-item>
            </router-link>

            <md-divider />

            <md-subheader>{{ $t('navs.subheadings.settings') }}</md-subheader>

            <router-link :to="{ name: 'parameters' }">
              <md-list-item @click="toggleDrawer">
                <md-icon>settings_remote</md-icon>
                <span class="md-list-item-text">{{
                  $t('navs.parameters')
                }}</span>
              </md-list-item>
            </router-link>

            <router-link :to="{ name: 'users' }">
              <md-list-item @click="toggleDrawer">
                <md-icon>person</md-icon>
                <span class="md-list-item-text">
                  {{ $t('navs.userManagement') }}
                </span>
              </md-list-item>
            </router-link>

            <md-divider />

            <md-subheader>{{ $t('navs.subheadings.other') }}</md-subheader>

            <a href="http://lilianlabs.com/" target="_blank">
              <md-list-item @click="toggleDrawer">
                <md-icon>link</md-icon>
                <span class="md-list-item-text">{{ $t('navs.aboutUs') }}</span>
              </md-list-item>
            </a>

            <a href="http://lilianlabs.com/kontakt/" target="_blank">
              <md-list-item @click="toggleDrawer">
                <md-icon>link</md-icon>
                <span class="md-list-item-text">{{ $t('navs.contact') }}</span>
              </md-list-item>
            </a>

            <a href="http://lilianlabs.com/impressum/" target="_blank">
              <md-list-item @click="toggleDrawer">
                <md-icon>link</md-icon>
                <span class="md-list-item-text">
                  {{ $t('navs.legalNotice') }}
                </span>
              </md-list-item>
            </a>

            <a
              href="http://lilianlabs.com/datenschutzerklaerung/"
              target="_blank"
            >
              <md-list-item @click="toggleDrawer">
                <md-icon>link</md-icon>
                <span class="md-list-item-text">
                  {{ $t('navs.privacyPolicy') }}
                </span>
              </md-list-item>
            </a>
          </md-list>
        </md-app-drawer>
      </template>

      <!-- Content -->
      <md-app-content md-theme="content" id="content">
        <template v-if="loggedIn">
          <keep-alive>
            <AppQuickNav v-if="$store.state.showQuickNav" />
          </keep-alive>
          <transition name="slide-fade">
            <router-view></router-view>
          </transition>

          <!-- User Account Settings Dialog -->
          <md-dialog :md-active.sync="showAccountSettingsDialog">
            <AppFormDialog
              :opts="formOpts"
              :values="accountFormValues"
              @close="showAccountSettingsDialog = false"
              @success="formSuccess"
            />
          </md-dialog>
        </template>

        <div v-else class="mb-4">
          <AppLogin />
        </div>

        <md-divider />
        <footer class="muted mt-2 mb-3">
          &copy; {{ year }} Lilian Labs GmbH.
          {{ $t('misc.allRightsReserved') }}.
          <a
            href="https://lilianlabs.com/impressum/"
            target="_blank"
            class="ml-2"
            >{{ $t('navs.legalNotice') }}</a
          >
          <a
            href="https://lilianlabs.com/datenschutzerklaerung/"
            target="_blank"
            class="ml-2"
            >{{ $t('navs.privacyPolicy') }}</a
          >
        </footer>
      </md-app-content>

      <!-- Loading Indicator -->
      <AppLoading v-if="$store.state.loading" />
    </md-app>

    <!-- Notification Popup -->
    <md-snackbar
      md-position="center"
      :md-active.sync="$store.state.showSnackbar"
      md-persistent
    >
      <span>{{ $store.state.snackbarMessage }}</span>
      <md-button class="md-primary" @click="$store.state.showSnackbar = false">
        {{ $t('actions.close') }}
      </md-button>
    </md-snackbar>
  </div>
</template>

<script>
import auth from '@/auth'
import api from '@/api'
import store from '@/store'
import config from '@/config'
import { Role, Feature } from '@/commons'

export default {
  name: 'App',

  store,

  data() {
    return {
      initDone: false,
      alertsScanTimeout: null,
      showDrawer: false,
      showDropdown: false,
      showAccountSettingsDialog: false,
      accountFormValues: {},
      formOpts: {
        titleKey: 'navs.accountSettings',
        endpoint: 'users/me',
        endpointWithoutId: true,
        deleteDisabled: true, // This hides the delete button
        inputs: {
          language: {
            type: 'component',
            component: 'InputLanguage',
          },
          mobilePhone: {
            title: 'Handynummer',
            type: 'text',
          },
          phone: {
            title: 'Telefonnummer',
            type: 'text',
          },
          notificationChannels: {
            type: 'component',
            component: 'InputNotificationChannels',
          },
          changePassword: {
            type: 'component',
            component: 'InputChangePassword',
          },
        },
        rows: [
          ['language'],
          ['mobilePhone', 'phone'],
          ['notificationChannels'],
          ['changePassword'],
        ],
      },
    }
  },

  beforeRouteLeave(to, from, next) {
    this.isLoading = true
    next()
  },

  async mounted() {
    await this.initApp()
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.loggedIn
    },

    year() {
      return new Date().getFullYear()
    },

    isSuperadmin() {
      return this.$auth.hasRole(Role.Superadmin)
    },

    isReseller() {
      return this.$auth.hasRole(Role.Reseller)
    },
  },

  methods: {
    async initApp() {
      // Set a handling for auth changes.
      auth.onChange = (authState) => {
        if (authState.loggedIn) {
          this.$router.replace({ name: 'entities' })
        } else {
          this.$router.replace('/')
        }

        this.scanForAlerts()
      }

      // Resume session.
      await auth.resumeSession()

      this.initDone = true
    },

    toggleDrawer() {
      this.showDrawer = !this.showDrawer
    },

    toggleDropdown() {
      this.showDropdown = !this.showDropdown
    },

    logout() {
      auth.logout()
    },

    async scanForAlerts() {
      // Scan for new alerts every 30 seconds
      // If logged in and user has access to "range" feature.
      if (this.$auth.loggedIn() && this.$auth.hasFeature(Feature.Range)) {
        const [err, alerts] = await api.get('alerts').send()

        if (!err) {
          this.$store.dispatch('setAlerts', alerts)
        }

        // Clear previous timeout
        if (this.alertsScanTimeout) {
          clearTimeout(this.alertsScanTimeout)
        }

        // Set new timeout
        this.alertsScanTimeout = setTimeout(() => {
          this.scanForAlerts()
        }, config.refreshAlertsIntervalInSeconds * 1000)
      } else {
        // Clear remaining timeout
        if (this.alertsScanTimeout) {
          clearTimeout(this.alertsScanTimeout)
        }

        // Unset alerts
        this.$store.dispatch('setAlerts', [])
      }
    },

    async openAccountSettingsForm() {
      this.$store.dispatch('loading')

      const user = this.$store.state.auth.user

      this.accountFormValues = user
      this.showAccountSettingsDialog = true

      this.$store.dispatch('success')
    },

    formSuccess() {
      // Reload the auth context.
      auth.fetchContext()

      this.$store.dispatch(
        'message',
        this.$t('states.changesSavedSuccessfully'),
      )
    },
  },
}
</script>

<style lang="scss">
$primary: #272d38;
$accent: #35baf2;
$background-light: #dde8e9;
$background: #e9e9e8;

@import '~vue-material/dist/theme/engine';
@include md-register-theme(
  'default',
  (
    primary: $accent,
    accent: #414750,
    background: white,
    theme: light,
  )
);
@include md-register-theme(
  'toolbar',
  (
    primary: $primary,
    accent: $primary,
    background: #2e3542,
    theme: light,
  )
);
@include md-register-theme(
  'drawer',
  (
    primary: $accent,
    accent: $accent,
    background: #2e3542,
    theme: dark,
  )
);
@include md-register-theme(
  'content',
  (
    primary: $accent,
    accent: $primary,
    background: white,
    theme: light,
  )
);
@import '~vue-material/dist/theme/all';

html,
body,
#app .md-app-scroller,
#content {
  background-color: $background;
}

#app .vue-treeselect__control {
  height: 44px;
}
.vue-treeselect__placeholder,
.vue-treeselect__single-value {
  line-height: 42px;
}
#app .vue-treeselect__control,
#app .vue-treeselect--open .vue-treeselect__control,
#app .vue-treeselect__menu {
  border-color: #888 !important;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  // transform: translateX(20px);
  opacity: 0;
}

#app .md-app {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// All drop downs need a higher index
// Otherwise they appear behind the dialog
.md-menu-content {
  z-index: 9999 !important;
}

// Make dialog scrollable and bigger
.md-dialog-container {
  width: 636px !important;
  height: auto !important;
  overflow-y: scroll !important;
}

// Sub options in select menu => QuickNavView
.child-option .md-list-item-content {
  padding-left: 32px;
}

// Fade out disabled input
.md-disabled:not(.md-list-item-content) {
  opacity: 0.7;
}

.md-app {
  #content {
    padding: 0 86px;
  }

  @media only screen and (max-width: 1200px) {
    #content {
      padding: 6px;
    }
  }

  .logo {
    img {
      margin: 48px 0 28px 16px;
      width: 200px;
    }
  }
  .logo-toolbar {
    height: 26px;
  }

  footer {
    a {
      text-transform: uppercase;
      font-weight: 700;
    }
  }

  // Give divider in drawer higher margins
  .md-drawer .md-list .md-divider {
    margin: 16px 0 8px 0;
  }

  // Width of drawer
  .md-drawer {
    width: 290px;
  }

  .toolbar-title a {
    color: white !important;
  }

  .md-card {
    margin-bottom: 30px;
  }

  .md-app-content .md-card {
    margin: 0 0 40px 0;
  }

  // Move fab bit closer to screen end
  .md-fab.md-fab-bottom-right {
    right: 12px;
    bottom: 18px;
  }

  // Default padding for #content
  .padding-top {
    padding-top: 20px;
  }

  // Same margin on top on all cards
  .md-card .md-title {
    margin-top: 8px;
  }

  .md-card-header,
  .md-card-content {
    padding: 24px;
  }

  // Some helpers
  .pb-0 {
    padding-bottom: 0;
  }

  .pt-0 {
    padding-top: 0;
  }

  .md-card .md-table {
    padding-bottom: 16px;
  }
}

/* Adjustments for Dosis font */
/* ------------------------------------------------------- */

#app,
.md-dialog,
.md-menu-content,
.AppLogin,
.md-list {
  font-family: 'Dosis' !important;
  font-weight: 500;

  .md-list-item-text {
    font-size: 1.1em !important;
    font-weight: 500;
  }

  .md-table-cell {
    font-size: 1.1em;
  }

  .md-title,
  .md-body-1,
  .md-body-2,
  .md-subhead,
  .md-button-content {
    font-size: 1.2em;
    font-weight: 700;
  }
  .md-subhead,
  .md-caption {
    font-size: 1em;
    font-weight: 600;
  }

  .md-title {
    font-weight: 700;
    text-transform: uppercase;
  }
  .md-drawer .md-list-item-container .md-list-item-text {
    font-size: 1.1em;
    font-weight: 700;
    text-transform: uppercase;
  }
  .md-subheader {
    text-transform: uppercase;
  }
  .md-table-head-label {
    text-transform: uppercase;
  }

  /* Button */
  .md-button.md-theme-content.md-primary:not(.md-icon-button) {
    border: 1px solid var(--md-theme-content-primary-on-background, #35baf2);
    color: var(--md-theme-content-primary-on-background, #35baf2);
  }
  .md-button:not(.md-fab) {
    border-radius: 18px;
  }
  // .md-button.md-theme-content.md-default:not(.md-icon-button) {
  //   border: 1px solid var(--md-theme-content-default-on-background, #000);
  //   color: var(--md-theme-content-default-on-background, #000);
  //   border-radius: 12px;
  // }
  .md-button .md-ripple {
    padding: 0 16px;
  }
  label {
    text-transform: uppercase;
  }
  /* Inputs */
  .md-field.md-focused .md-input,
  .md-field.md-focused .md-textarea,
  .md-field.md-has-value .md-input,
  .md-field.md-has-value .md-textarea {
    font-weight: 700;
  }
}

/* Helper classes */
/* ------------------------------------------------------- */

.ta-r {
  text-align: right !important;
}
.ta-c {
  text-align: center;
}
.uc {
  text-transform: uppercase;
}
.pos-r {
  position: relative;
}
.pos-a {
  position: absolute;
}
.pos-f {
  position: fixed;
}
.mt-0 {
  margin-top: 0;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0 {
  margin-left: 0;
}
.mr-0 {
  margin-right: 0;
}
.mt-1 {
  margin-top: 8px;
}
.mb-1 {
  margin-bottom: 8px;
}
.ml-1 {
  margin-left: 8px;
}
.mr-1 {
  margin-right: 8px;
}
.mt-2 {
  margin-top: 16px;
}
.mb-2 {
  margin-bottom: 16px;
}
.ml-2 {
  margin-left: 16px;
}
.mr-2 {
  margin-right: 16px;
}
.mt-3 {
  margin-top: 24px;
}
.mb-3 {
  margin-bottom: 24px;
}
.ml-3 {
  margin-left: 24px;
}
.mr-3 {
  margin-right: 24px;
}
.mt-4 {
  margin-top: 32px;
}
.mb-4 {
  margin-bottom: 32px;
}
.ml-4 {
  margin-left: 32px;
}
.mr-4 {
  margin-right: 32px;
}
.mt-5 {
  margin-top: 48px;
}
.mb-5 {
  margin-bottom: 48px;
}
.ml-4 {
  margin-left: 48px;
}
.mr-4 {
  margin-right: 48px;
}
.text-smaller {
  font-size: 0.94em;
}
.text-italic {
  font-style: italic;
}
.muted {
  opacity: 0.7;
}
</style>

<template>
  <div>
    <div class="padding-top">
      <AppList
        :opts="opts"
        :formOpts="formOpts"
        :items="items"
        @formSuccess="fetchData"
      />
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import api from '@/api'
import { Role } from '@/commons'

export default {
  name: 'PageClients',

  data: () => ({
    items: [],
    opts: {
      titleKey: 'navs.clients',
      subtitleKey: 'misc.manageClients',
      searchName: 'title',
      createTextKey: 'actions.createClient',
      columns: [
        {
          name: 'title',
          label: 'labels.client',
          type: 'edit',
        },
        {
          name: 'address',
        },
        {
          name: 'reseller',
          formatter: (reseller) => {
            return (reseller && reseller.title) || '-'
          },
        },
        {
          name: 'switchClient',
          type: 'component',
          component: 'AppListSwitchClient',
        },
      ],
    },
    formOpts: {
      titleKey: 'navs.client',
      endpoint: 'clients',
      inputs: {
        title: {
          type: 'text',
          validation: {
            required,
          },
        },
        address: {
          type: 'text',
          validation: {
            required,
          },
        },
        //  Removed in favor for feature handling (InputFeatures).
        // measurementCommentInAppEnabled: {
        //   type: 'switch'
        // },
        multiFactorAuthDisabled: {
          type: 'switch',
        },
        features: {
          type: 'component',
          component: 'InputFeatures',
        },
        // token: {
        //   title: 'API Key',
        //   type: 'component',
        //   component: 'input-client-token',
        // },
      },
      rows: [
        ['title'],
        ['address'],
        // ['measurementCommentInAppEnabled'],
        ['multiFactorAuthDisabled'],
        ['features'],
      ],
    },
  }),

  mounted() {
    // TODO: their must be a better way than this.
    if (this.$auth.hasRole(Role.Superadmin)) {
      this.formOpts.inputs.reseller = {
        title: 'Reseller',
        type: 'component',
        component: 'InputReseller',
      }
      this.formOpts.rows.push(['reseller'])
    }

    this.fetchData()
  },

  methods: {
    async fetchData() {
      const [err, clients] = await api.get('clients').send()
      if (err) return
      this.items = clients
    },
  },
}
</script>

<template>
  <div class="mt-2 mb-2">
    <!-- Bundle Select -->
    <md-field class="mb-0">
      <label :for="name">{{ $t(`labels.features`) }}</label>

      <md-select
        :name="name"
        :id="name"
        v-model="selectedBundle"
        md-dense
        :disabled="disabled"
      >
        <md-option
          v-for="(bundle, index) in bundles"
          :key="'bundle' + index"
          :value="bundle.name"
        >
          {{ bundle.configs.title }}
        </md-option>
      </md-select>
    </md-field>

    <!-- Custom Features Select -->
    <div v-if="selectedBundle === 'custom'">
      <div>
        <md-switch
          v-for="(feature, index) in features"
          :key="'feature' + index"
          v-model="selectedFeatures"
          :value="feature.name"
          class="md-primary"
        >
          {{ feature.configs.title }}
        </md-switch>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config'

export default {
  name: 'InputFeatures',

  props: {
    value: Object,
    name: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    config,
    model: null,
    selectedBundle: 'custom',
    selectedFeatures: [],
  }),

  computed: {
    bundles() {
      return this.$store.state.auth.localizedMainConfigs.featureBundles
    },
    features() {
      return this.$store.state.auth.localizedMainConfigs.features
    },
  },

  async mounted() {
    this.model = { ...this.value }

    this.selectedFeatures = this.model['features'] || []
    this.selectedBundle = this.model['featureBundle'] || 'custom'
  },

  methods: {
    onChange(name, value) {
      this.model[name] = value
      this.$emit('input', this.model)
    },
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.model = {
          ...this.model,
          ...this.value,
        }
      },
    },

    selectedFeatures() {
      this.onChange('features', this.selectedFeatures)
    },

    selectedBundle() {
      this.onChange('featureBundle', this.selectedBundle)
    },
  },
}
</script>

<template>
  <md-button @click="showResellerUsers" class="md-accent">
    {{ $t('labels.manageResellerUsers') }}
  </md-button>
</template>

<script>
export default {
  name: 'AppListShowResellerUsers',

  props: {
    item: Object,
  },

  methods: {
    showResellerUsers() {
      let item = this.item
      this.$router.push({ name: 'reseller-users', params: { id: item._id } })
    },
  },
}
</script>

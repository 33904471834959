import Vue from 'vue'
import App from './App'
import router from './router'
import i18n from './i18n'
import lodash from 'lodash'

// UI
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
// VueMaterial theme is defined in App.vue

// Plugins
import Vuelidate from 'vuelidate'
import VueCharts from 'vue-chartjs'
import VueMoment from 'vue-moment'
import draggable from 'vuedraggable'
import Vuex from 'vuex'
import VueLodash from 'vue-lodash'

// Mixins
import AuthMixin from '@/mixins/auth.mixin'

// Include mixins
Vue.mixin(AuthMixin)

// Include plugins
Vue.use(VueMaterial)
Vue.use(VueCharts)
Vue.use(VueMoment)
Vue.use(Vuelidate)
Vue.use(Vuex)
Vue.use(VueLodash, { lodash })

// External components
Vue.component('vue-draggable', draggable)

// Include all components from component folder
// Code from: https://v2.vuejs.org/v2/guide/components-registration.html
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const requireComponent = require.context(
  // The relative path of the components folder
  './components',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /[A-Z]\w+\.(vue|js)$/,
)

requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, ''),
    ),
  )

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig,
  )
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  i18n,
  components: {
    App,
  },
  template: '<App/>',
})

<template>
  <div class="measurements-list">
    <md-card-content class="pb-0" v-if="!hideShowFlaggedSwitch">
      <div class="md-layout">
        <div class="md-layout-item">
          <md-switch v-model="showFlagged" class="md-primary">
            {{ $t('labels.showFlagged') }}
          </md-switch>
        </div>

        <div class="md-layout-item ta-r">
          <md-button class="md-primary" @click="exportXLSX">
            {{ $t('actions.exportXlsx') }}
          </md-button>
        </div>
      </div>
    </md-card-content>

    <md-table md-sort="name" md-sort-order="asc">
      <md-table-row>
        <md-table-head>{{ $t('labels.date') }}</md-table-head>

        <md-table-head>{{ $t('labels.time') }}</md-table-head>

        <md-table-head class="time-cell">
          {{ $t('labels.measuredBy') }}
        </md-table-head>

        <md-table-head
          md-numeric
          v-for="parameter in pointData.parameters"
          :key="parameter.label"
        >
          {{ parameter.title }} {{ getUnitLabel(parameter.unit) }}
        </md-table-head>

        <md-table-head></md-table-head>
      </md-table-row>

      <template v-for="(item, index) in pointData.measurements">
        <md-table-row
          :key="index"
          :class="{ 'is-flagged': item.flagged }"
          v-if="showFlagged || !item.flagged"
        >
          <md-table-cell
            v-if="
              index === 0 ||
              pointData.measurements[index - 1].flagged ||
              !isSameDate(
                pointData.measurements[index].createdAt,
                pointData.measurements[index - 1].createdAt,
              )
            "
            md-label="Datum"
            class="first-cell"
            style="width: 120px"
          >
            {{ item.createdAt | moment('DD.MM.YYYY') }}
          </md-table-cell>

          <md-table-cell
            v-else
            class="first-cell no-date-cell"
            style="width: 120px"
          ></md-table-cell>

          <md-table-cell class="time-cell" style="width: 100px">
            {{ item.createdAt | moment('HH:mm') }}
            <md-icon v-if="item.synced" style="margin-top: -2px">sync</md-icon>
          </md-table-cell>

          <md-table-cell :md-label="$t('labels.measuredBy')">
            <router-link
              v-if="item.createdBy"
              :to="{ name: 'user', params: { id: item.createdBy._id } }"
              class="link"
            >
              {{ item.createdBy.lastName }}, {{ item.createdBy.firstName }}
            </router-link>

            <div v-else>{{ $t('labels.deletedUser') }}</div>
          </md-table-cell>

          <md-table-cell
            v-for="parameter in pointData.parameters"
            :key="parameter.label"
            :md-label="parameter.label"
            :md-sort-by="parameter.name"
            :md-numeric="true"
          >
            <div
              v-if="
                item.results[parameter.uniqueName] !== null &&
                typeof item.results[parameter.uniqueName] !== 'undefined'
              "
              :class="[
                'value',
                'status-' + item.results[parameter.uniqueName].status,
              ]"
            >
              <template v-if="!item.results[parameter.uniqueName].offset">
                {{ item.results[parameter.uniqueName].label }}
              </template>

              <template v-else>
                <span>
                  *{{ item.results[parameter.uniqueName].label }}
                  <md-tooltip md-direction="top">
                    Offset: {{ item.results[parameter.uniqueName].offset }}
                  </md-tooltip>
                </span>
              </template>
            </div>

            <div v-else>-</div>
          </md-table-cell>

          <md-table-cell class="edit-cell" md-label="" style="width: 40px">
            <md-button
              v-if="!item.flagged"
              @click="openFlagDialog(item)"
              class="md-icon-button md-default"
            >
              <md-icon>delete_sweep</md-icon>
            </md-button>

            <md-button
              v-else
              @click="showFlaggedInfo(item)"
              class="md-icon-button md-default"
            >
              <md-icon>info</md-icon>
            </md-button>
          </md-table-cell>

          <md-table-cell class="edit-cell" md-label="" style="width: 40px">
            <md-button
              v-if="!item.comment || item.comment === ''"
              @click="openCommentDialog(item)"
              class="md-icon-button md-default"
            >
              <md-icon>comment</md-icon>
            </md-button>

            <md-button
              v-else
              @click="openCommentDialog(item)"
              class="md-icon-button md-primary"
              style="width: 40px"
            >
              <md-icon>comment</md-icon>
            </md-button>
          </md-table-cell>
        </md-table-row>
      </template>
    </md-table>

    <md-card-content class="uc p4">
      * {{ $t('labels.offsetApplied') }}
    </md-card-content>

    <md-dialog-prompt
      :md-active.sync="showFlaggingPrompt"
      v-model="flaggedComment"
      :md-title="$t('labels.reasonForFlagging')"
      md-input-maxlength="100"
      :md-input-placeholder="$t('labels.addComment')"
      :md-cancel-text="$t('actions.cancel')"
      :md-confirm-text="$t('actions.flagMeasurement')"
      @md-confirm="flagMeasurement"
    />

    <md-dialog v-if="flaggedInfoItem" :md-active.sync="showFlaggedInfoDialog">
      <md-dialog-title>{{ $t('labels.flaggedMeasurement') }}</md-dialog-title>

      <md-dialog-content>
        <div>
          <span class="md-caption">{{ $t('labels.flaggedBy') }}:</span>
          {{ flaggedInfoItem.flaggedBy.firstName }}
          {{ flaggedInfoItem.flaggedBy.lastName }}
        </div>

        <div>
          <span class="md-caption">{{ $t('labels.comment') }}:</span>
          {{ flaggedInfoItem.flaggedComment }}
        </div>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-primary" @click="showFlaggedInfoDialog = false">
          {{ $t('actions.ok') }}
        </md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-dialog v-if="showCommentDialog" :md-active.sync="showCommentDialog">
      <md-dialog-title>{{ $t('labels.measurementComments') }}</md-dialog-title>

      <md-dialog-content>
        <md-field>
          <label>{{ $t('labels.commentText') }}</label>

          <md-textarea
            v-model="comment"
            class="comment-textarea"
            maxlength="1000"
          ></md-textarea>
        </md-field>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-default" @click="showCommentDialog = false">
          {{ $t('actions.cancel') }}
        </md-button>

        <md-button class="md-primary" @click="postComment">
          {{ $t('actions.save') }}
        </md-button>
      </md-dialog-actions>
    </md-dialog>

    <md-snackbar :md-active.sync="isSaved">
      {{ $t('states.changesSavedSuccessfully') }}
    </md-snackbar>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import api from '@/api'

const toLower = (text) => {
  return text.toString().toLowerCase()
}

const searchByName = (items, searchName, term) => {
  if (term) {
    return items.filter((item) =>
      toLower(item[searchName]).includes(toLower(term)),
    )
  }
  return items
}

export default {
  name: 'MeasurementsList',

  data: () => ({
    search: null,
    renderedItems: [],
    showDialog: false,
    formValues: {},
    isSaved: false,
    showFlagged: false,
    showFlaggingPrompt: false,
    itemToFlag: null,
    flaggedComment: '',
    showFlaggedInfoDialog: false,
    flaggedInfoItem: null,
    showCommentDialog: false,
    comment: null,
    commentItem: null,
  }),

  props: {
    pointData: Object,
    hideShowFlaggedSwitch: {
      default: false,
    },
  },

  methods: {
    openGoto() {
      if (this.prevGoto !== this.goto) {
        this.prevGoto = this.goto
        this.$router.push({ name: 'point', params: { id: this.goto } })
      }
    },

    isSameDate(date1, date2) {
      return (
        moment(date1).format('YYYY-MM-DD') ===
        moment(date2).format('YYYY-MM-DD')
      )
    },

    createNewItem() {
      window.alert('Noop')
    },

    searchOnTable() {
      this.renderedItems = searchByName(
        this.items,
        this.opts.searchName,
        this.search,
      )
    },

    onSelect() {
      let vm = this
      this.$router.push({ name: vm.opts.routeName })
    },

    openForm(item) {
      this.showDialog = true
      this.formValues = item
    },

    getUnitLabel(unitName) {
      if (!unitName || unitName === 'none') return ''

      const unitTypes = this.$store.state.auth.localizedMainConfigs.unitTypes

      const unitType = _.find(unitTypes, { name: unitName })

      if (!unitType) return ''

      return `(${unitType.configs.titleShort})`
    },

    openFlagDialog(item) {
      this.itemToFlag = item
      this.showFlaggingPrompt = true
    },

    async flagMeasurement() {
      let item = this.itemToFlag
      let updates = {
        flagged: true,
        flaggedComment: this.flaggedComment,
      }
      const [err, updatedItem] = await api
        .put('measurements/:id', { params: { id: item._id }, body: updates })
        .send()
      if (err) return
      _.merge(item, updatedItem)
      this.$forceUpdate()
    },

    showFlaggedInfo(item) {
      this.flaggedInfoItem = item
      this.showFlaggedInfoDialog = true
    },

    openCommentDialog(item) {
      this.commentItem = item
      this.comment = item.comment
      this.showCommentDialog = true
    },

    async postComment() {
      let item = this.commentItem
      let comment = this.comment
      const [err, updatedItem] = await api
        .put('measurements/:id', {
          params: { id: item._id },
          body: { comment },
        })
        .send()
      if (err) return
      _.merge(item, updatedItem)
      this.showCommentDialog = false
      this.$forceUpdate()
    },

    exportXLSX() {
      this.$emit('exportXLSX', {})
    },
  },
}
</script>

<style lang="scss">
$comment-textarea-height: 320px;
.md-field .md-textarea.comment-textarea {
  height: $comment-textarea-height;
  min-height: $comment-textarea-height;
  max-height: $comment-textarea-height;
}
.measurements-list {
  .first-cell {
    font-weight: 700;
  }
  .no-date-cell {
    border-top: 0;
  }

  // Move time all to the left to align with end of cut off border for astheticy
  .time-cell {
    .md-table-cell-container {
      padding-left: 0;
    }
  }
  .md-table-row .md-table-head:nth-child(2) {
    .md-table-head-label {
      padding-left: 0;
    }
  }

  .edit-cell,
  .export-column {
    text-align: right;
  }

  .md-table-cell-container {
    padding: 0 8px 0 24px;
  }

  .md-table-cell {
    height: 42px;
  }

  .is-flagged {
    opacity: 0.6;
    font-style: italic;
    color: red;
  }

  .is-flagged .md-icon.md-theme-default.md-icon-font {
    color: red;
    margin-right: 18px;
  }

  .value {
    display: inline-block;
    padding: 3px 7px;
    font-weight: 500;
    border-radius: 8px;
  }
  .status-1 {
    background: #c8e6c9;
  }
  .status-2 {
    background: #fff9c4;
  }
  .status-3 {
    background: #ffcdd2;
  }

  a.link {
    display: block;
    color: #222;
    font-weight: 700;
    &:hover {
      color: #222;
    }
  }
}
</style>

import Vue from 'vue'
import Router from 'vue-router'

import PageEntities from '@/components/PageEntities'
import PageEntity from '@/components/PageEntity'
import PagePlace from '@/components/PagePlace'
import PagePoint from '@/components/PagePoint'
import PageAlerts from '@/components/PageAlerts'
import PageParameters from '@/components/PageParameters'
import PageUsers from '@/components/PageUsers'
import PageUser from '@/components/PageUser'
import PageClients from '@/components/PageClients'
import PageResellers from '@/components/PageResellers'
import PageResellerUsers from '@/components/PageResellerUsers'
import PageUsages from '@/components/PageUsages'
import PageExport from '@/components/PageExport'
import PageMeasurements from '@/components/PageMeasurements'
import PageSuperadmins from '@/components/PageSuperadmins'

import _ from 'lodash'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/entities',
      name: 'entities', // List of entities
      component: PageEntities,
    },
    {
      path: '/entities/:id', // List of places
      name: 'entity',
      component: PageEntity,
    },
    {
      path: '/places/:id', // List of points
      name: 'place',
      component: PagePlace,
    },
    {
      path: '/points/:id', // List of measurements
      name: 'point',
      component: PagePoint,
    },
    {
      path: '/measurements', // List if measurements
      name: 'measurements',
      component: PageMeasurements,
    },
    {
      path: '/alerts', // List if alerts
      name: 'alerts',
      component: PageAlerts,
    },
    {
      path: '/parameters', // List if parameters
      name: 'parameters',
      component: PageParameters,
    },
    {
      path: '/clients', // List if clients
      name: 'clients',
      component: PageClients,
    },
    {
      path: '/resellers', // List if resellers
      name: 'resellers',
      component: PageResellers,
    },
    {
      path: '/resellers/:id/users', // List if resellers
      name: 'reseller-users',
      component: PageResellerUsers,
    },
    {
      path: '/users', // List of users
      name: 'users',
      component: PageUsers,
    },
    {
      path: '/users/:id', // Profile page of user
      name: 'user',
      component: PageUser,
    },
    {
      path: '/usages', // Profile page of user
      name: 'usages',
      component: PageUsages,
    },
    {
      path: '/export', // export data
      name: 'export',
      component: PageExport,
    },

    {
      path: '/superadmins', // export data
      name: 'superadmins',
      component: PageSuperadmins,
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.path !== '/' && !store.state.auth.loggedIn) {
    // Prevent router from opening a route if user is not authenticated
    next({ path: '/' })
  } else {
    store.dispatch(
      'showQuickNav',
      _.includes(['entities', 'entity', 'place', 'point'], to.name),
    )
    next()
  }
})

export default router

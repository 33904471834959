<template>
  <div class="app-form-dialog">
    <md-dialog-title class="title">
      {{ opts.title || $t(opts.titleKey) }}
    </md-dialog-title>
    <div v-if="confirmDialogActive">
      <md-dialog-content>
        <p class="md-headline">{{ $t('misc.confirmDeletion') }}</p>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button
          class="md-default"
          @click="confirmDialogActive = false"
          :disabled="sending"
        >
          {{ $t('actions.cancel') }}
        </md-button>
        <md-button
          v-if="!opts.isCreate && !opts.deleteDisabled"
          class="delete-button"
          @click="remove"
          :disabled="sending"
        >
          {{ $t('actions.delete') }}
        </md-button>
      </md-dialog-actions>
    </div>

    <form
      v-else-if="model"
      novalidate
      @keydown.enter.prevent
      @submit.prevent="validateForm"
    >
      <md-dialog-content>
        <div
          v-for="(cols, index) in opts.rows"
          :key="index"
          class="md-layout md-gutter"
        >
          <div
            v-for="name in cols"
            :key="name"
            class="md-layout-item md-small-size-100"
          >
            <div v-if="name === ''"></div>

            <!-- ---------- SELECT ---------- -->
            <md-field
              v-else-if="opts.inputs[name].type === 'select'"
              :class="getValidationClass(name)"
            >
              <label :for="name">{{ $t(`labels.${name}`) }}</label>
              <md-select
                :name="name"
                :id="name"
                v-model="model[name]"
                md-dense
                :disabled="sending"
              >
                <md-option
                  v-for="(v, k) in opts.inputs[name].options"
                  :key="k"
                  :value="k"
                >
                  {{ v }}
                </md-option>
              </md-select>
              <span
                v-if="$v.model[name] && !$v.model[name].required"
                class="md-error"
              >
                {{ $t('states.pleaseFillIn') }}
              </span>
            </md-field>

            <!-- ---------- TEXTAREA ---------- -->
            <md-field
              v-else-if="opts.inputs[name].type === 'textarea'"
              :class="getValidationClass(name)"
            >
              <label :for="name">{{ $t(`labels.${name}`) }}</label>
              <md-textarea
                v-model="model[name]"
                :name="name"
                :id="name"
                :disabled="sending"
              ></md-textarea>
              <span
                v-if="$v.model[name] && !$v.model[name].required"
                class="md-error"
              >
                {{ $t('states.pleaseFillIn') }}
              </span>
            </md-field>

            <!-- ---------- SWITCH ---------- -->
            <md-switch
              v-else-if="opts.inputs[name].type === 'switch'"
              :class="getValidationClass(name)"
              v-model="model[name]"
              :name="name"
              :disabled="sending"
              class="md-primary"
            >
              {{ $t(`labels.${name}`) }}
            </md-switch>

            <!-- ---------- COMPONENT ---------- -->
            <component
              v-else-if="opts.inputs[name].type === 'component'"
              :is="opts.inputs[name].component"
              v-model="model"
              :name="name"
              :disabled="sending"
              :payload="opts.inputs[name].payload"
            />

            <!-- ---------- NUMBER ---------- -->
            <md-field
              v-else-if="opts.inputs[name].type === 'number'"
              :class="getValidationClass(name)"
            >
              <label :for="name">{{ $t(`labels.${name}`) }}</label>
              <md-input
                :type="opts.inputs[name].type"
                :name="name"
                :id="name"
                v-model.number="model[name]"
                :disabled="sending"
              />
              <span
                v-if="$v.model[name] && !$v.model[name].required"
                class="md-error"
                >{{ $t('states.pleaseFillIn') }}</span
              >
            </md-field>

            <!-- ---------- DEFAULT ---------- -->
            <md-field v-else :class="getValidationClass(name)">
              <label :for="name">{{ $t(`labels.${name}`) }}</label>
              <md-input
                :type="opts.inputs[name].type"
                :name="name"
                :id="name"
                v-model="model[name]"
                :disabled="sending"
                :maxlength="opts.inputs[name].maxlength || null"
              />
              <span
                v-if="$v.model[name] && !$v.model[name].required"
                class="md-error"
                >{{ $t('states.pleaseFillIn') }}</span
              >
            </md-field>
          </div>
        </div>
      </md-dialog-content>

      <div class="progress-bar-wrapper">
        <md-progress-bar md-mode="indeterminate" v-if="sending" />
      </div>

      <md-divider />

      <div class="md-layout footer">
        <div class="md-layout-item">
          <md-button
            v-if="!opts.isCreate && !opts.deleteDisabled"
            class="delete-button"
            @click="confirmDialogActive = true"
            :disabled="sending"
          >
            {{ $t('actions.delete') }}
          </md-button>
        </div>
        <div class="md-layout-item">
          <md-dialog-actions>
            <md-button class="md-default" @click="cancel" :disabled="sending">
              {{ $t('actions.cancel') }}
            </md-button>
            <md-button
              v-if="opts.isCreate"
              type="submit"
              class="md-primary"
              :disabled="sending"
            >
              {{ $t('actions.create') }}
            </md-button>
            <md-button
              v-else
              type="submit"
              class="md-primary"
              :disabled="sending"
            >
              {{ $t('actions.update') }}
            </md-button>
          </md-dialog-actions>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import api from '@/api'
import _ from 'lodash'

export default {
  name: 'AppFormDialog',

  props: {
    opts: {
      title: String,
      titleKey: String,
      inputs: Object,
      isCreate: Boolean,
      endpoint: String,
      defValues: Object,
    },
    values: Object,
  },

  data: () => ({
    model: null,
    sending: false,
    confirmDialogActive: false,
  }),

  mounted() {
    // First assign values to model
    this.model = _.cloneDeep({
      ...this.opts.defValues,
      ...this.values,
    })
  },

  validations() {
    const inputs = this.opts.inputs
    const valis = { model: {} }

    for (let k in inputs) {
      if (inputs.hasOwnProperty(k)) {
        let input = inputs[k]
        if (input.validation) {
          valis.model[k] = input.validation
        }
      }
    }
    return valis
  },

  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.model[fieldName]
      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty,
        }
      }
    },

    clearForm() {
      this.$v.$reset()
    },

    async save() {
      this.sending = true
      const [err, res] = await api[this.opts.isCreate ? 'post' : 'put'](
        this.opts.endpoint +
          (this.opts.isCreate || this.opts.endpointWithoutId ? '' : '/:id'),
        {
          params: { id: this.model._id || undefined },
          body: this.model,
        },
      ).send()

      if (err) {
        this.failed(err)
      } else {
        this.done(res)
      }
    },

    async remove() {
      this.sending = true
      const [err, res] = await api
        .delete(this.opts.endpoint + '/:id', { params: { id: this.model._id } })
        .send()
      if (err) {
        this.failed(err)
      } else {
        this.done(res)
      }
    },

    validateForm() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.save()
      }
    },

    cancel() {
      this.close()
    },

    failed() {
      this.sending = false
    },

    done() {
      this.$emit('success', { ...this.model })
      this.sending = false
      this.close()
    },

    close() {
      this.model = null
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
.app-form-dialog {
  padding-top: 20px;

  // Align switch to same height as input field next to it (see Places form)
  .md-switch {
    // margin-top: 26px;

    // Give switch label same font size as input fields (see Places form)
    .md-switch-label {
      font-size: 16px;
    }
  }

  // Footer area needs a bit more distance to the divider
  .footer {
    margin-top: 4px;
  }

  // Make sure progress bar does not push content away when showing
  // by wrapping it into a div with same size
  .progress-bar-wrapper {
    height: 4px;
  }

  // Decrease margin from title to form content
  .md-dialog-title {
    margin-bottom: 6px;
  }

  // Increase button and x-padding of dialog action buttons
  // Default was 8px
  .md-dialog-actions {
    padding: 8px 12px 12px 12px;
  }

  .delete-button {
    color: red;
    margin-left: 12px;
  }
}
</style>

<template>
  <div v-if="model && options">
    <md-divider />
    <p class="uc">{{ $t('labels.permittedEntities') }}</p>
    <div v-if="model.role === 'admin'">
      {{ $t('misc.adminsHaveAccessToAllEntities') }}
    </div>
    <div v-else>
      <div style="height: 300px">
        <treeselect
          :max-height="300"
          :multiple="true"
          :value="selection"
          :options="options"
          :always-open="true"
          :placeholder="$t('actions.select')"
          @input="onTreeselectInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import api from '@/api'

export default {
  name: 'InputEntities',

  components: { Treeselect },

  props: {
    value: Object,
    name: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    model: null,
    options: [],
    selection: [],
  }),

  async mounted() {
    this.model = {
      [this.name]: [],
      ...this.value,
    }

    this.selection = this.value[this.name] || []

    let opts = []
    const [err, entities] = await api.get('entities').send()
    if (err) return
    // const newarr = entities.concat(entities, entities, entities, entities, entities, entities, entities, entities)
    const newarr = entities
    newarr.map((e) => {
      opts.push({
        id: e._id,
        label: e.title,
      })
    })
    this.options = opts
  },

  methods: {
    onTreeselectInput(val) {
      this.selection = val
    },
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.model = {
          ...this.model,
          ...this.value,
        }
      },
    },

    selection() {
      this.model[this.name] = this.selection
      this.$emit('input', this.model)
    },
  },
}
</script>

<template>
  <span>{{ $t('labels.' + item.role) }}</span>
</template>

<script>
export default {
  name: 'AppListUserRole',
  props: {
    item: Object,
  },
}
</script>

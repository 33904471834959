<template>
  <div>
    <AppList
      :opts="opts"
      :formOpts="formOpts"
      :items="items"
      @formSuccess="formSuccess"
    />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import api from '@/api'

const handleApiData = (vm, data) => {
  vm.items = data.points
  vm.opts.title = data.title
  vm.$store.dispatch('pushBreadcrumb', {
    position: 3,
    title: data.title,
    to: { name: 'place', params: { id: data._id } },
  })
}

export default {
  name: 'PagePlace',

  data: () => ({
    items: [],
    opts: {
      items: [],
      title: '',
      subtitleKey: 'navs.points',
      createTextKey: 'actions.createPoint',
      routeName: 'point',
      editRouteName: 'point',
      searchName: 'title',
      columns: [
        {
          name: 'status',
          type: 'status',
        },
        {
          name: 'title',
          type: 'link',
          link: {
            name: 'point',
          },
        },
        {
          name: 'description',
        },
      ],
    },
    formOpts: {
      titleKey: 'navs.point',
      endpoint: 'points',
      inputs: {
        title: {
          type: 'text',
          validation: {
            required,
          },
        },
        description: {
          type: 'textarea',
        },
        parameterConfigs: {
          type: 'component',
          component: 'InputPointParameters',
          payload: {
            isForPoint: true,
          },
        },
      },
      rows: [['title'], ['description'], ['parameterConfigs']],
    },
  }),

  mounted() {
    this.formOpts.defValues = {
      place: this.$route.params.id,
      entity: this.$store.state.entity._id,
      status: 1,
    }
    this.fetchData()
  },

  methods: {
    formSuccess() {
      this.fetchData()
    },

    async fetchData() {
      const [err, data] = await api
        .get('places/:id', { params: { id: this.$route.params.id } })
        .send()
      if (err) return
      handleApiData(this, data)
    },
  },
}
</script>

<style scoped></style>

<template>
  <div class="app-list">
    <md-card>
      <md-card-header>
        <slot name="header">
          <div class="md-layout">
            <div class="md-layout-item">
              <h1 class="md-title title">
                {{ opts.title || $t(opts.titleKey) }}
              </h1>
              <h2
                v-if="opts.subtitle || opts.subtitleKey"
                class="md-caption subtitle"
              >
                {{ opts.subtitle || $t(opts.subtitleKey) }}
              </h2>
            </div>
          </div>
        </slot>
      </md-card-header>

      <md-divider />

      <md-table v-model="items">
        <md-table-empty-state
          md-icon="help_outline"
          :md-label="$t('states.noResults')"
        >
          <md-button
            v-if="opts.formOpts"
            class="md-primary md-raised"
            @click="openCreateForm"
          >
            {{ $t(opts.createTextKey) }}
          </md-button>
        </md-table-empty-state>

        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <!-- TODO: add a better :key value -->
          <template v-for="(column, index) in opts.columns">
            <md-table-cell
              v-if="showColumn(column)"
              :key="'table-col-' + index"
              :md-label="
                column.label ? $t(column.label) : $t(`labels.${column.name}`)
              "
              :md-sort-by="column.name"
              :md-numeric="column.type === 'number'"
              :class="column.type === 'status' ? 'status-cell' : ''"
            >
              <AppListStatus
                v-if="column.type === 'status'"
                :status="item[column.name]"
              />

              <span v-else-if="column.type === 'datetime'">
                {{
                  getColumnContent(item, column) | moment('DD.MM.YYYY, HH:MM')
                }}
              </span>

              <span v-else-if="column.type === 'date'">
                {{ getColumnContent(item, column) | moment('DD.MM.YYYY') }}
              </span>

              <component
                v-else-if="column.type === 'component'"
                :is="column.component"
                :item="item"
              />

              <router-link
                v-else-if="column.type === 'link'"
                class="link"
                :to="{
                  name: column.link.name,
                  params: {
                    id: column.link.idPath
                      ? _.get(item, column.link.idPath)
                      : item._id,
                  },
                }"
              >
                {{ getColumnContent(item, column) }}
              </router-link>

              <template v-else-if="column.type === 'edit'">
                <a href="#" class="link" @click="openForm(item)">
                  {{ getColumnContent(item, column) }}
                </a>
              </template>

              <template v-else-if="column.type === 'main'">
                <span class="main-cell-content">{{
                  getColumnContent(item, column)
                }}</span>
              </template>

              <template v-else>
                {{ getColumnContent(item, column) }}
              </template>
            </md-table-cell>
          </template>

          <md-table-cell v-if="formOpts" class="edit-cell" md-label="">
            <md-button
              @click="openForm(item)"
              md-mini
              class="md-icon-button md-primary"
            >
              <md-icon>edit</md-icon>
            </md-button>
          </md-table-cell>
        </md-table-row>
      </md-table>

      <md-progress-bar
        class="md-accent"
        md-mode="indeterminate"
        v-if="isLoading"
      />
    </md-card>

    <md-dialog
      v-if="formOpts"
      :md-active.sync="showDialog"
      :md-click-outside-to-close="false"
    >
      <AppFormDialog
        :opts="formOpts"
        :values="formValues"
        @close="showDialog = false"
        @success="formSuccess"
      />
    </md-dialog>

    <md-snackbar
      :md-active.sync="showSnackbar"
      :md-duration="2000"
      md-persistent
    >
      <span>{{ $t('states.changesSavedSuccessfully') }}</span>
      <md-button class="md-primary" @click="showSnackbar = false">{{
        $t('actions.close')
      }}</md-button>
    </md-snackbar>

    <md-button
      v-if="formOpts"
      class="md-fab md-fab-bottom-right md-fixed"
      @click="openCreateForm"
    >
      <md-icon>add</md-icon>
      <md-tooltip md-direction="top">{{ $t('actions.create') }}</md-tooltip>
    </md-button>
  </div>
</template>

<script>
import _ from 'lodash'

import { Feature } from '@/commons'

const toLower = (text) => {
  return text.toString().toLowerCase()
}

const searchByName = (items, searchName, term) => {
  if (term) {
    return items.filter((item) =>
      toLower(item[searchName]).includes(toLower(term)),
    )
  }
  return items
}

export default {
  name: 'AppList',

  data: () => ({
    search: null,
    renderedItems: [],
    showDialog: false,
    showSnackbar: false,
    formValues: {},
    isSaved: false,
  }),

  props: {
    opts: {
      title: String,
      titleKey: String,
      subtitle: String,
      subtitleKey: String,
      columns: Array,
      createTextKey: String,
      searchEnabled: Boolean,
      searchName: String,
      routeName: String,
      createRouteName: String,
      editRouteName: String,
    },
    isLoading: Boolean,
    formOpts: Object,
    items: Array,
  },

  computed: {
    hasFeatureRange() {
      return this.$auth.hasFeature(Feature.Range)
    },
  },

  methods: {
    searchOnTable() {
      this.renderedItems = searchByName(
        this.items,
        this.opts.searchName,
        this.search,
      )
    },

    getTypeClass(type) {
      return 'column-type-' + type
    },

    onSelect() {
      let vm = this
      this.$router.push({ name: vm.opts.routeName })
    },

    openForm(item) {
      this.formValues = item
      this.formOpts.isCreate = false
      this.showDialog = true
    },

    openCreateForm() {
      this.formValues = {}
      this.formOpts.isCreate = true
      this.showDialog = true
    },

    formSuccess() {
      this.showSnackbar = true
      this.$emit('formSuccess')
    },

    getColumnContent(item, column) {
      const val = _.get(item, column.name)
      if (typeof column.formatter === 'function') {
        return column.formatter(val)
      }
      return val
    },

    showColumn(column) {
      // Do not show if it is status column and user has no "range" feature access.
      return !(column.type === 'status' && !this.hasFeatureRange)
    },
  },

  beforeUpdated() {
    this.renderedItems = this.items
  },
}
</script>

<style lang="scss" scoped>
.app-list {
  .status-cell {
    width: 100px;
  }

  .edit-cell {
    text-align: right;
    width: 100px;
  }

  .main-cell-content {
    font-weight: 700;
  }

  a.link {
    display: block;
    color: #222;
    margin: -19px -12px -18px;
    padding: 19px 12px 18px;
    font-weight: 700;
  }

  // Add more space above the table
  .md-table {
    margin-top: 16px;
  }
}
</style>

import api from '@/api'
import store, { defaultAuthState } from '@/store'
import config from '@/config'
import { t, setLocaleAsync } from '@/i18n'
import { Role } from '@/commons'

export default {
  /**
   * Attempts to start a session for given credentials.
   *
   * @param {string} email
   * @param {string} password
   * @returns
   */
  async login(data) {
    const [err, res] = await api.post('auth/login', { body: data }).send()
    if (err) return false

    if (res.success) {
      const accessToken = res.accessToken

      this.setAuthHeader(accessToken)
      this.setAccessToken(accessToken)
      await this.fetchContext()
    }

    return res
  },

  /**
   * Continues a session and returns true if successful
   * and false if not.
   */
  async resumeSession() {
    const accessToken = this.getAccessToken()

    // Load a fallback locale. This will be overwritten by fetchContext
    // in the next step.
    await this.setLocaleAsync(config.fallbackLocale)

    if (accessToken) {
      this.setAuthHeader(accessToken)
      await this.fetchContext()
    } else {
      this.logout()
    }
  },

  /**
   * Loads the authenticated user into the stores auth state.
   *
   * @returns
   */
  async fetchContext() {
    const [err, context] = await api.get('auth/context').send()

    if (err) {
      this.logout()
    } else {
      // We extract for more clarity on what is going on.
      const {
        localizedMainConfigs,
        mainConfigs,
        user,
        sessionExpiresAt,
        permissions,
        features,
      } = context

      // Update the locale to user preference.
      await this.setLocaleAsync(user.language)

      // Only user with a non-employee role have access.
      if (user.role !== Role.Employee) {
        this.setAuthState({
          user,
          localizedMainConfigs,
          mainConfigs,
          sessionExpiresAt,
          permissions,
          features,
          loggedIn: true,
        })
      } else {
        this.logout()
        store.dispatch('error', t('errors.userNotAdmin'))
      }
    }
  },

  /**
   * Changes the user's client and reloads the authenticated user.
   *
   * @param {*} clientId
   */
  async switchClient(clientId) {
    const [err, res] = await api
      .post('auth/switch-client', {
        body: { clientId },
      })
      .send()
    if (err) this.logout()

    if (res.success) {
      // Update auth user.
      await this.fetchContext()

      // Update Vue store objects
      store.dispatch('setEntity', {})
    }
  },

  logout() {
    this.removeAuthHeader()
    this.removeAccessToken()
    this.resetAuthState()
  },

  setAuthHeader(accessToken) {
    api.set({
      headers: (current) => ({
        ...current,
        Authorization: 'Bearer ' + accessToken,
      }),
    })
  },

  removeAuthHeader() {
    api.set({
      headers: (current) => {
        delete current.Authorization
        return current
      },
    })
  },

  async setLocaleAsync(locale) {
    return setLocaleAsync(locale)
  },

  setAccessToken(token) {
    localStorage.setItem('accessToken', token)
  },

  getAccessToken() {
    return localStorage.getItem('accessToken')
  },

  removeAccessToken() {
    return localStorage.removeItem('accessToken')
  },

  getUser() {
    return store.state.auth.user
  },

  getLocalizedMainConfigs() {
    return store.state.auth.localizedMainConfigs
  },

  loggedIn() {
    return store.state.auth.loggedIn
  },

  resetAuthState() {
    this.setAuthState(defaultAuthState)
  },

  setAuthState(data) {
    store.dispatch('setAuth', data)
    this.onChange(data)
  },

  hasRole(role) {
    return store.state.auth.user.role === role
  },

  hasFeature(feature) {
    if (Array.isArray(store.state.auth.features)) {
      return store.state.auth.features.includes(feature)
    }

    return false
  },

  onChange() {},
}

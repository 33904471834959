import { createInstance } from 'apiator'
import { getLocale } from './i18n'
import config from './config'
import store from './store'

const api = createInstance({
  baseUrl: config.apiBaseUrl,
})

const onSend = () => {
  store.dispatch('loading')
}

const onResult = async ({ error: err }) => {
  if (err) {
    const locale = getLocale()
    let message = {
      en: 'Something went wrong. Please try again.',
      de: 'Etwas ist schief gelaufen. Bitte versuchen Sie es erneut.',
    }[locale]
    if (err.is.NETWORK_ERROR) {
      message = {
        en: 'Could not reach server. Please check your internet connection.',
        de: 'Server nicht erreicht. Bitte überprüfen Sie Ihre Internetverbindung.',
      }[locale]
    } else if (err.is.HTTP_ERROR) {
      const res = await err.response.json()
      if (res.messages && res.messages[locale]) {
        message = res.messages[locale]
      } else if (res && res.message) {
        message = res.message
      }
    }
    store.dispatch('error', message)
  } else {
    store.dispatch('success')
  }
}

api.on('send', onSend)
api.on('result', onResult)

export default api

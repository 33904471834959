<template>
  <div v-if="model">
    <md-divider />
    <md-switch
      class="md-primary mt-2"
      v-model="changePassword"
      :disabled="disabled"
    >
      {{ $t('labels.changePassword') }}
    </md-switch>
    <div v-if="changePassword">
      <md-field>
        <label>{{ $t('labels.newPassword') }}</label>
        <md-input
          type="password"
          v-model="model.password"
          :disabled="disabled"
        />
      </md-field>
      <md-field>
        <label>{{ $t('labels.repeatNewPassword') }}</label>
        <md-input
          type="password"
          v-model="model.passwordRepeat"
          :disabled="disabled"
        />
      </md-field>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputChangePassword',

  props: {
    value: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    changePassword: false,
    model: null,
  }),

  mounted() {
    this.model = { ...this.value }
  },

  watch: {
    model: {
      deep: true,
      handler(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>

<template>
  <md-field>
    <label :for="name">{{ $t(`labels.${name}`) }}</label>

    <md-select
      :name="name"
      :id="name"
      v-model="selectedReseller"
      md-dense
      :disabled="disabled || loading"
    >
      <md-option :value="'none'">{{ $t('labels.none') }}</md-option>
      <md-option
        v-for="(reseller, index) in resellers"
        :key="'reseller' + index"
        :value="reseller._id"
      >
        {{ reseller.title }}
      </md-option>
    </md-select>
  </md-field>
</template>

<script>
import api from '@/api'

export default {
  name: 'InputReseller',

  props: {
    value: Object,
    name: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    loading: true,
    model: null,
    resellers: null,
    selectedReseller: null,
    options: [],
  }),

  async mounted() {
    this.model = { ...this.value }

    await this.fetchData()

    let initialValue = 'none'
    if (this.model[this.name] && this.model[this.name]._id) {
      initialValue = this.model[this.name]._id
    }

    this.selectedReseller = initialValue
  },

  methods: {
    async fetchData() {
      this.loading = true
      const [err, resellers] = await api.get('resellers').send()
      if (err) return
      this.resellers = resellers
      this.loading = false
    },
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.model = {
          ...this.model,
          ...this.value,
        }
      },
    },

    selectedReseller() {
      this.model[this.name] = this.selectedReseller
      this.$emit('input', this.model)
    },
  },
}
</script>

import _ from 'lodash'

import store from '@/store'
import auth from '@/auth'

export class ParameterHelper {
  /**
   * Returns the localized parameter type for a given parmeter type name.
   *
   * @param {string} name
   * @returns
   */
  static getParameterTypeFromConfigs(name) {
    const localizedMainConfigs = auth.getLocalizedMainConfigs()

    const parameterType =
      _.find(localizedMainConfigs.systemParameterTypes, { name }) ||
      _.find(localizedMainConfigs.customParameterTypes, { name }) ||
      _.find(localizedMainConfigs.legacySystemParameterTypes, { name }) ||
      false

    if (!parameterType) {
      store.dispatch('error', 'This parameter is not supported.')
      return
    }

    return parameterType
  }

  /**
   * Checks if a parameter type name is a custom parameter.
   *
   * @param {string} name
   * @returns
   */
  static isCustomParameter(name) {
    const localizedMainConfigs = auth.getLocalizedMainConfigs()
    const isCustom = !!_.find(localizedMainConfigs.customParameterTypes, {
      name: name,
    })

    return isCustom
  }
}

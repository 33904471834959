<template>
  <div>
    <div class="padding-top">
      <AppList
        :opts="opts"
        :formOpts="formOpts"
        :items="items"
        @formSuccess="formSuccess"
      />
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import api from '@/api'
import { Role } from '@/commons'

export default {
  name: 'PageSuperadmins',

  data: () => ({
    items: [],
    opts: {
      titleKey: 'navs.superadmins',
      subtitleKey: 'misc.manageUserAccess',
      editRouteName: 'user',
      createRouteName: 'user',
      createTextKey: 'actions.addNewUser',
      columns: [
        {
          name: 'lastName',
          type: 'link',
          link: {
            name: 'user',
            idPath: '_id',
          },
        },
        {
          name: 'firstName',
        },
        {
          name: 'email',
        },
        {
          name: 'role',
          type: 'component',
          component: 'AppListUserRole',
        },
      ],
    },
    formOpts: {
      titleKey: 'navs.user',
      endpoint: 'users',
      inputs: {
        firstName: {
          title: 'Vorname',
          type: 'text',
          validation: {
            required,
          },
        },
        lastName: {
          title: 'Nachname',
          type: 'text',
          validation: {
            required,
          },
        },
        email: {
          title: 'E-Mail',
          type: 'text',
          validation: {
            required,
            email,
          },
        },
        language: {
          type: 'component',
          component: 'InputLanguage',
          validation: {
            required,
          },
        },
      },
      rows: [
        ['firstName', 'lastName'],
        ['email', 'language'],
      ],
      defValues: {
        role: Role.Superadmin,
      },
    },
  }),

  mounted() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      const [err, users] = await api
        .get('users', { query: { role: Role.Superadmin } })
        .send()
      if (err) return
      this.items = users
    },

    formSuccess() {
      this.fetchData()
    },
  },
}
</script>

<template>
  <div class="padding-top">
    <md-card>
      <md-card-header>
        <div class="md-layout">
          <div class="md-layout-item">
            <h1 class="md-title title">
              {{ user.lastName }}, {{ user.firstName }}
            </h1>
          </div>
        </div>
      </md-card-header>

      <md-divider></md-divider>

      <md-card-content>
        <md-list class="md-double-line">
          <md-subheader>{{ $t('labels.userInfo') }}</md-subheader>

          <md-list-item>
            <md-icon class="md-primary">accessibility</md-icon>
            <div class="md-list-item-text">
              <span>{{ convertRole(user.role) }}</span>
              <span>{{ $t('labels.role') }}</span>
            </div>
          </md-list-item>

          <md-list-item>
            <md-icon class="md-primary">translate</md-icon>
            <div class="md-list-item-text">
              <span>{{ convertLanguage(user.language) }}</span>
              <span>{{ $t('labels.language') }}</span>
            </div>
          </md-list-item>

          <md-subheader>{{ $t('labels.contactData') }}</md-subheader>

          <md-list-item v-if="user.mobilePhone">
            <md-icon class="md-primary">stay_current_portrait</md-icon>
            <div class="md-list-item-text">
              <span>{{ user.mobilePhone }}</span>
              <span>{{ $t('labels.mobilePhone') }}</span>
            </div>
          </md-list-item>

          <md-list-item v-if="user.phone">
            <md-icon class="md-primary">phone</md-icon>
            <div class="md-list-item-text">
              <span>{{ user.phone }}</span>
              <span>{{ $t('labels.phone') }}</span>
            </div>
          </md-list-item>

          <md-list-item>
            <md-icon class="md-primary">email</md-icon>

            <div class="md-list-item-text">
              <span>{{ user.email }}</span>
              <span>{{ $t('labels.email') }}</span>
            </div>
          </md-list-item>

          <md-list-item v-if="user.companyName">
            <md-icon class="md-primary">business</md-icon>

            <div class="md-list-item-text">
              <span>{{ user.companyName }}</span>
              <span>{{ $t('labels.companyName') }}</span>
            </div>
          </md-list-item>
        </md-list>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import api from '@/api'

const handleApiData = (vm, data) => {
  vm.user = data
}

export default {
  name: 'PageUser',

  data: () => ({
    user: {},
  }),

  mounted() {
    this.fetchData()
  },

  methods: {
    convertRole(val) {
      return {
        admin: 'Admin',
        employee: 'Mitarbeiter',
      }[val]
    },

    convertLanguage(locale) {
      return this.$t('locales.' + locale)
    },

    async fetchData() {
      const [err, data] = await api
        .get('users/:id', { params: { id: this.$route.params.id } })
        .send()
      if (err) return
      handleApiData(this, data)
    },
  },
}
</script>

<style scoped>
.md-list {
  margin: 0 -16px;
}
</style>

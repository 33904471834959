<template>
  <div>
    <md-card>
      <md-card-header>
        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <div class="header">
              <h1 class="md-title">{{ title }}</h1>
              <h2 class="md-body-1">{{ subtitle }}</h2>
            </div>
          </div>
          <div class="md-layout-item md-size-30 md-xsmall-size-100">
            <md-field>
              <label for="gotoPoint">{{ $t('actions.goToPoint') }}</label>
              <md-select
                v-model="goto"
                @md-closed="openGoto"
                name="gotoPoint"
                id="gotoPoint"
              >
                <md-option
                  v-for="(point, index) in pointData.place.points"
                  :key="index"
                  :value="point._id"
                >
                  {{ point.title }}</md-option
                >
              </md-select>
            </md-field>
          </div>
        </div>

        <div class="md-layout md-gutter">
          <div class="md-layout-item md-xsmall-size-100 md-small-size-50">
            <md-field>
              <label for="range">{{ $t('labels.layout') }}</label>
              <md-select v-model="layout" name="range" id="range">
                <md-option value="list">{{ $t('labels.list') }}</md-option>
                <md-option value="graph">{{ $t('labels.graph') }}</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-xsmall-size-100 md-small-size-50">
            <md-field>
              <label for="range">{{ $t('labels.range') }}</label>
              <md-select v-model="range" name="range" id="range">
                <md-option value="7days">{{
                  $t('labels.last7Days')
                }}</md-option>
                <md-option value="30days">{{
                  $t('labels.last30Days')
                }}</md-option>
                <md-option value="daterange">{{
                  $t('labels.customDateRange')
                }}</md-option>
                <md-option value="last100Measurements">{{
                  $t('labels.last100Measurements')
                }}</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-xsmall-size-100 md-small-size-20">
            <md-datepicker
              v-if="range === 'daterange'"
              v-model="dateStart"
              :md-immediately="true"
              :md-close-on-blur="false"
            >
              <label>{{ $t('labels.from') }}</label>
            </md-datepicker>
          </div>
          <div class="md-layout-item md-xsmall-size-100 md-small-size-20">
            <md-datepicker
              v-if="range === 'daterange'"
              v-model="dateEnd"
              ref="test"
              :md-immediately="true"
              :md-close-on-blur="false"
            >
              <label>{{ $t('labels.to') }}</label>
            </md-datepicker>
          </div>
          <div class="md-layout-item md-xsmall-size-100 md-small-size-10 ta-r">
            <div class="mt-2">
              <md-button @click="updateMeasurements" class="md-primary">
                <md-icon>refresh</md-icon>
              </md-button>
            </div>
          </div>
        </div>
      </md-card-header>

      <md-divider />

      <md-empty-state
        v-if="pointData.measurements.length === 0"
        md-icon="help_outline"
        :md-description="$t('states.noResults')"
      >
      </md-empty-state>

      <div v-else>
        <MeasurementsList
          v-if="layout === 'list'"
          :pointData="pointData"
          @exportXLSX="exportXLSX"
        />

        <md-card-content v-else-if="layout === 'graph'">
          <MeasurementsChart :pointData="pointData" />
          <MeasurementsChart :pointData="pointData" />
        </md-card-content>
      </div>
    </md-card>
  </div>
</template>

<script>
import moment from 'moment'

import api from '@/api'
import { ExportHelper } from '@/helpers'

const handleApiData = (vm, data) => {
  vm.pointData = data
  vm.title = data.place.title
  vm.subtitle = data.title
  vm.$store.dispatch('pushBreadcrumb', {
    position: 4,
    title: data.title,
    to: { name: 'point', params: { id: data._id } },
  })
}

const getFilter = (vm = {}) => {
  // Default is '30days'
  let limit = undefined
  let dateStart = moment().subtract(30, 'days').format()
  let dateEnd = moment().format()
  if (vm.range === 'daterange' && vm.dateStart && vm.dateEnd) {
    dateStart = moment(vm.dateStart).startOf('day').format()
    dateEnd = moment(vm.dateEnd).endOf('day').format()
  } else if (vm.range === '7days') {
    dateStart = moment().subtract(7, 'days').format()
    dateEnd = moment().format()
  } else if (vm.range === 'last100Measurements') {
    dateStart = null
    dateEnd = null
    limit = 100
  }
  return { dateStart, dateEnd, limit }
}

export default {
  name: 'PagePoint',

  data: () => ({
    items: [],
    title: '',
    subtitle: '',
    range: '30days',
    dateStart: null,
    dateEnd: null,
    layout: 'list',
    prevGoto: null,
    goto: null,
    pointData: {
      place: {},
      parameters: {},
      measurements: {},
    },
  }),

  created() {
    // watch the params of the route to fetch the data again
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true },
    )
  },

  watch: {
    range: function () {
      this.updateMeasurements()
    },

    dateStart: function () {
      this.updateMeasurements()
    },

    dateEnd: function () {
      this.updateMeasurements()
    },
  },

  methods: {
    openGoto() {
      if (this.prevGoto !== this.goto) {
        this.prevGoto = this.goto
        this.$router.push({ name: 'point', params: { id: this.goto } })
        this.goto = null
      }
    },

    updateMeasurements() {
      this.fetchData(getFilter(this))
    },

    exportXLSX() {
      const points = [this.pointData._id]
      const filter = getFilter(this)
      const dateStart = filter.dateStart
      const dateEnd = filter.dateEnd

      ExportHelper.createMeasurementsExport({ points, dateStart, dateEnd })
    },

    async fetchData() {
      const filter = getFilter(this)
      const [err, data] = await api
        .get('points/:id', {
          params: { id: this.$route.params.id },
          query: filter,
        })
        .send()
      if (err) return
      handleApiData(this, data)
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  margin: 0 0 20px;
}
</style>

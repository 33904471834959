<template>
  <div v-if="model">
    <div class="md-layout md-gutter">
      <div class="md-layout-item">
        <md-field>
          <label for="type">{{ $t('labels.parameterType') }}</label>
          <md-select
            name="type"
            id="type"
            v-model="model.type"
            md-dense
            :disabled="!isCreate"
          >
            <md-optgroup :label="$t('labels.systemParameters')">
              <md-option
                v-for="parameterType in localizedMainConfigs.systemParameterTypes"
                :key="parameterType.name"
                :value="parameterType.name"
              >
                {{ parameterType.configs.title }}
              </md-option>
            </md-optgroup>
            <md-optgroup :label="$t('labels.customParameters')">
              <md-option
                v-for="parameterType in localizedMainConfigs.customParameterTypes"
                :key="parameterType.name"
                :value="parameterType.name"
              >
                {{ parameterType.defaults.title }}
              </md-option>
            </md-optgroup>
          </md-select>
        </md-field>
      </div>
      <div class="md-layout-item">
        <div v-if="isCalculated" class="mt-3">
          <md-icon>superscript</md-icon> {{ $t('labels.calculated') }}
        </div>
        <md-switch
          v-else-if="showManualSwitch"
          class="md-primary mt-3"
          v-model="model.manual"
          :disabled="disabled"
        >
          {{ $t('labels.manualInput') }}
        </md-switch>
        <div v-else-if="showParameterConfig || !isCreate" class="mt-3">
          <md-icon>touch_app</md-icon> {{ $t('labels.manualInput') }}
        </div>
      </div>
    </div>

    <div v-if="calculationRequirementTitles.length">
      <md-icon>info</md-icon>
      {{ $t('labels.requiredParameters') }}:
      {{ calculationRequirementTitles.join(', ') }}
    </div>

    <div v-if="showParameterConfig || !isCreate">
      <div v-if="showTitleInputs" class="md-layout md-gutter">
        <div class="md-layout-item">
          <md-field>
            <label for="title">{{ $t('labels.title') }}</label>
            <md-input name="title" v-model="model.title" :disabled="disabled" />
          </md-field>
        </div>
        <div class="md-layout-item">
          <md-field>
            <label for="title">{{ $t('labels.titleShort') }}</label>
            <md-input
              name="title"
              v-model="model.titleShort"
              :disabled="disabled"
              maxlength="10"
            />
          </md-field>
        </div>
      </div>

      <div class="md-layout md-gutter">
        <div class="md-layout-item" v-if="model.type === 'number'">
          <md-field>
            <label for="type">{{ $t('labels.unit') }}</label>
            <md-select
              name="type"
              id="type"
              v-model="model.unit"
              md-dense
              :disabled="disabled || model._id"
            >
              <md-option
                v-for="unitType in localizedMainConfigs.unitTypes"
                :key="unitType.name"
                :value="unitType.name"
              >
                {{ unitType.configs.title }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item" v-if="model.type === 'number'">
          <md-field>
            <label for="numDecimals">{{ $t('labels.numDecimals') }}</label>
            <md-select
              v-model.number="model.numDecimals"
              name="numDecimals"
              id="numDecimals"
            >
              <md-option
                v-for="numDecimalType in localizedMainConfigs.numDecimalTypes"
                :key="numDecimalType.name"
                :value="numDecimalType.name"
              >
                {{ numDecimalType.configs.title }}
              </md-option>
            </md-select>
          </md-field>
        </div>
      </div>

      <div v-if="model.type === 'select'">
        <md-chips
          v-model="model.options"
          :md-placeholder="$t('actions.addOption')"
        />
      </div>

      <!-- Removed on client request in Sep 2022 due to the removal of slider input in app. -->
      <!-- <div v-if="showMinMaxValue" class="mt-2">
        <div class="md-subheading">{{ $t('labels.showMinMaxValue') }}</div>

        <div class="md-layout md-gutter">
          <div class="md-layout-item">
            <md-field>
              <label for="title">{{ $t('labels.minValue') }}</label>
              <md-input
                name="title"
                v-model.number="model.minValue"
                :disabled="disabled"
              />
            </md-field>
          </div>
          <div class="md-layout-item">
            <md-field>
              <label for="title">{{ $t('labels.maxValue') }}</label>
              <md-input
                name="title"
                v-model.number="model.maxValue"
                :disabled="disabled"
              />
            </md-field>
          </div>
        </div>
      </div> -->

      <InputParameterOffset
        v-if="showOffset"
        v-model="model"
        :payload="parameterOffsetPayload"
      />

      <InputParameterRange v-if="showRange" v-model="model" />
    </div>
  </div>
</template>

<script>
import { ParameterHelper } from '@/helpers/parameter.helper'
import auth from '@/auth'

export default {
  name: 'InputParameter',

  props: {
    value: Object,
    disabled: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    model: null,
    localizedMainConfigs: {},
    parameterTypeName: null,
    parameterTypeConfigs: null,
    calculationRequirementTitles: [],
    isManual: false,
    showRange: false,
    showOffset: false,
    showManualSwitch: false,
    showTitleInputs: false,
    showParameterConfig: false,
    parameterOffsetPayload: {},
  }),

  watch: {
    model: {
      deep: true,
      handler(val) {
        this.$emit('input', val)
      },
    },

    'model.type'(type) {
      this.parameterTypeChanged(type)
    },
  },

  computed: {
    isCreate: function () {
      // Checks if we are creating or editing a parameter
      return this.model._id === null || typeof this.model._id === 'undefined'
    },

    // See above in template for why commented out.
    // showMinMaxValue: function() {
    //   // First check if min/max is enabled.
    //   if (this.parameterTypeConfigs.manualMinMaxEnabled) {
    //     // If it is it always show if it is only a manual parameter (Lilian not enabled)
    //     // or if Lilian is enabled but manual was chosen.
    //     return !this.parameterTypeConfigs.lilianEnabled || this.model.manual
    //   }
    //   return false
    // }
  },

  mounted() {
    this.model = { ...this.value }
    this.localizedMainConfigs = auth.getLocalizedMainConfigs()
    this.parameterTypeName = this.model.type
    this.updateFormUi()
  },

  methods: {
    updateFormUi() {
      const type = this.parameterTypeName
      if (type) {
        const parameterType = ParameterHelper.getParameterTypeFromConfigs(type)
        const isCustom = ParameterHelper.isCustomParameter(type)
        const configs = parameterType.configs
        this.parameterTypeConfigs = configs

        this.calculationRequirementTitles = []
        if (parameterType.configs.calculationRequirements) {
          parameterType.configs.calculationRequirements.map((uniqueName) => {
            const requiredParameterType =
              ParameterHelper.getParameterTypeFromConfigs(uniqueName)
            this.calculationRequirementTitles.push(
              requiredParameterType.configs.title,
            )
          })
        }

        this.isCalculated = configs.calculationEnabled
        this.showManualSwitch =
          configs.lilianEnabled && !configs.calculationEnabled
        this.showRange = configs.rangeEnabled
        this.showOffset = configs.offsetEnabled
        this.parameterOffsetPayload = {
          isForPoint: false,
          offsetMin: configs.offsetMin,
          offsetMax: configs.offsetMax,
        }
        this.disabled = false
        this.showParameterConfig = true
        this.showTitleInputs = isCustom
        if (this.isCreate) {
          // Set model to parameter defaults.
          this.model = {
            ...parameterType.defaults,
            type,
          }
        }
      }
    },

    parameterTypeChanged(type) {
      this.parameterTypeName = type
      this.updateFormUi()
    },
  },
}
</script>

<style scoped></style>

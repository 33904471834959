<template>
  <div>
    <div class="padding-top">
      <AppList :opts="opts" :items="$store.state.alerts" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageAlerts',

  data: () => ({
    items: [],
    opts: {
      titleKey: 'navs.alerts',
      subtitleKey: 'misc.currentAlertsOfAllEntities',
      createTextKey: 'actions.createPlace',
      routeName: 'points',
      columns: [
        {
          name: 'status',
          type: 'status',
        },
        {
          name: 'measurement.createdAt',
          label: 'labels.createdAt',
          type: 'datetime',
        },
        {
          name: 'entity.title',
          label: 'labels.entity',
          type: 'link',
          link: {
            name: 'entity',
            idPath: 'entity._id',
          },
        },
        {
          name: 'place.title',
          label: 'labels.place',
          type: 'link',
          link: {
            name: 'place',
            idPath: 'place._id',
          },
        },
        {
          name: 'point.title',
          label: 'labels.point',
          type: 'link',
          link: {
            name: 'point',
            idPath: 'point._id',
          },
        },
        {
          //   name: 'affectedParameters',
          //   label: 'Betroffene Parameter'
          // }, {
          name: 'measurement.createdBy.lastName',
          label: 'labels.measuredBy',
          type: 'link',
          link: {
            name: 'user',
            idPath: 'measurement.createdBy._id',
          },
        },
      ],
    },
  }),
}
</script>

<style scoped></style>

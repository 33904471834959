<template>
  <div>
    <AppList
      :opts="opts"
      :formOpts="formOpts"
      :items="items"
      @formSuccess="formSuccess"
    />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import api from '@/api'

const handleApiData = (vm, data) => {
  vm.items = data.places
  vm.opts.title = data.title
  vm.$store.dispatch('setEntity', data)
  vm.$store.dispatch('pushBreadcrumb', {
    position: 2,
    title: data.title,
    to: { name: 'entity', params: { id: data._id } },
  })
}

export default {
  name: 'PageEntity',

  data: () => ({
    items: [],
    opts: {
      title: '',
      subtitleKey: 'navs.places',
      createTextKey: 'navs.createPlace',
      routeName: 'place',
      searchName: 'title',
      columns: [
        {
          name: 'status',
          type: 'status',
        },
        {
          name: 'title',
          type: 'link',
          link: {
            name: 'place',
          },
        },
        {
          name: 'description',
        },
      ],
    },
    formOpts: {
      titleKey: 'navs.place',
      endpoint: 'places',
      inputs: {
        title: {
          type: 'text',
          validation: {
            required,
          },
        },
        description: {
          type: 'textarea',
        },
        // Disabled on client request.
        // 'poolVolume': {
        //   title: 'Beckenvolumen (m3)',
        //   type: 'text',
        //   validation: {}
        // },
        // 'poolIndoor': {
        //   title: 'Innenbecken',
        //   type: 'switch'
        // },
        lat: {
          title: 'Latitude',
          type: 'number',
          step: 0.1,
        },
        lng: {
          title: 'Longitude',
          type: 'number',
          step: 0.1,
        },
      },
      rows: [
        ['title'],
        ['description'],
        // Disabled on client request.
        // ['address'],
        ['lat', 'lng'],
        // ['poolVolume', 'poolIndoor'],
      ],
    },
  }),

  created() {
    this.formOpts.defValues = {
      entity: this.$route.params.id,
      status: 1,
    }
  },

  mounted() {
    this.fetchData()
  },

  methods: {
    formSuccess() {
      this.showSnackbar = true
      this.fetchData()
    },

    async fetchData() {
      const [err, data] = await api
        .get('entities/:id', { params: { id: this.$route.params.id } })
        .send()
      if (err) return
      handleApiData(this, data)
    },
  },
}
</script>

<style scoped></style>

<template>
  <md-progress-bar class="loading" md-mode="indeterminate" />
</template>

<script>
export default {
  name: 'AppLoading',
}
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  top: 59px;
  left: 0;
  right: 0;
  z-index: 9999;
}
</style>

import Vue from 'vue'
import VueI18n from 'vue-i18n'

import config from '@/config'
import api from '@/api'

Vue.use(VueI18n)

const loadedLanguages = []

// Export VueI18n instance with options.
const i18n = new VueI18n({
  fallbackLocale: 'en',
})

export function setLocale(locale) {
  i18n.locale = locale || 'en'
  localStorage.setItem('locale', locale)
  document.querySelector('html').setAttribute('lang', locale)
}

export function getLocale() {
  return i18n.locale
}

export function t(text) {
  return i18n.t(text)
}

export async function setLocaleAsync(locale) {
  // Check if locale is supported and use fallback if needed.
  if (!locale || !config.supportedLocales.includes(locale)) {
    locale = localStorage.getItem('locale') || config.fallbackLocale
  }

  // If the same language.
  if (i18n.locale === locale) {
    return
  }

  // If the language was already loaded.
  if (loadedLanguages.includes(locale)) {
    setLocale(locale)
    return
  }

  // If the language hasn't been loaded yet.
  const [err, res] = await api
    .get('locales/:locale.json', { params: { locale } })
    .send()
  if (err) return

  i18n.setLocaleMessage(locale, res)
  loadedLanguages.push(locale)

  return setLocale(locale)
}

export default i18n

export const Role = {
  /**
   * Cannot access the manager. Only use the app.
   */
  Employee: 'employee',
  /**
   * Can manage its own client.
   */
  Admin: 'admin',
  /**
   * Can manage clients created by themselves.
   */
  Reseller: 'reseller',
  /**
   * Can manage everything, including resellers.
   */
  Superadmin: 'superadmin',
}

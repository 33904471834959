import moment from 'moment'
import XLSX from 'xlsx'
import _ from 'lodash'

import api from '@/api'
import { t } from '@/i18n'
import store from '@/store'
import config from '@/config'

export class ExportHelper {
  /**
   * Fetches points for export and generates XSLX file for download.
   *
   * @param {*} param0
   * @returns
   */
  static async createMeasurementsExport({ points, dateStart, dateEnd }) {
    const [err, fetchedPoints] = await api
      .get('points/export', {
        query: { points, dateStart, dateEnd },
      })
      .send()

    if (err) return

    this._buildPointsXlsx({ points: fetchedPoints, dateStart, dateEnd })
  }

  /**
   * Helper function that generates XLSX file.
   *
   * @param {*} param0
   */
  static _buildPointsXlsx({ points, dateStart, dateEnd }) {
    try {
      var workbook = XLSX.utils.book_new()
      let filename =
        `${t('labels.measurements')}__` +
        moment(dateStart).format('YYYY-MM-DD') +
        ' - ' +
        moment(dateEnd).format('YYYY-MM-DD') +
        '.xlsx'
      let pointCount = 0

      points.map((point) => {
        // Build sheet for this point.
        pointCount++
        let header = [
          t('labels.createdAt'),
          t('labels.measuredBy'),
          t('labels.status'),
        ]
        let uniqueNamesToTitle = {}

        point.parameters.map((p) => {
          header.push(p.title)
          uniqueNamesToTitle[p.uniqueName] = p.title
        })

        header.push(t('labels.comment'))

        // Change width of first two columns.
        let worksheetColSettings = [
          { wch: 16 }, // Set the character length.
          { wpx: 120 }, // Set the pixel.
        ]

        if (point.measurements && point.measurements.length) {
          // Build sheet rows.
          let rows = []
          point.measurements.map((m) => {
            let row = {
              [t('labels.createdAt')]: moment(m.createdAt).format(
                'YYYY-MM-DD HH:mm',
              ),
              [t('labels.measuredBy')]: m.createdBy
                ? m.createdBy.lastName + ', ' + m.createdBy.firstName
                : t('labels.deletedUser'),
              [t('labels.status')]: m.flagged ? t('states.error') : 'OK',
            }
            _.each(uniqueNamesToTitle, (title, uniqueName) => {
              // By default export result value. If however it is a over-, under range label
              // export that label instead.
              const { value, label } = m.results[uniqueName]

              const cellValue = [
                config.underRangeLabel,
                config.overRangeLabel,
              ].includes(label)
                ? label
                : value

              row[title] = cellValue
            })
            row[t('labels.comment')] = m.comment || ''
            rows.push(row)
          })

          // Create worksheet.
          // Add title in first row.
          let worksheetTitle = `${point.place.entity.title}: ${point.place.title} - ${point.title}`
          let worksheetLabel =
            pointCount +
            ' ' +
            `${point.place.entity.title}: ${point.place.title} - ${point.title}`.replace(
              /[\\/?*[\]]/gi, // Sheet name cannot contain : \ / ? * [ ]
              '',
            )
          var worksheet = XLSX.utils.aoa_to_sheet([[worksheetTitle]])

          // Add data to sheet
          XLSX.utils.sheet_add_json(worksheet, rows, {
            header: header,
            origin: 2,
            blankRows: false,
          })

          // Append worksheet to workbook.
          if (worksheetLabel.length > 31) {
            worksheetLabel =
              worksheetLabel.substr(0, 8) +
              '...' +
              worksheetLabel.substr(worksheetLabel.length - 20)
          }
          worksheet['!cols'] = worksheetColSettings
          XLSX.utils.book_append_sheet(workbook, worksheet, worksheetLabel)
        }
      })

      // Create XLSX file.
      XLSX.writeFile(workbook, filename)
    } catch (err) {
      if (err.message === 'Workbook is empty') {
        store.dispatch('error', t('errors.nothingToExport'))
      } else {
        store.dispatch('error', t('errors.defaultMessage'))
      }
    }
  }
}

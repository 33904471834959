<template>
  <div v-if="model">
    <md-divider />
    <div class="body-1 uc mt-2">{{ $t('labels.notificationChannels') }}</div>
    <md-switch
      v-for="(opt, index) in options"
      :key="index"
      class="md-primary"
      v-model="selection"
      :value="opt.value"
      :disabled="disabled"
    >
      {{ $t(opt.label) }}
    </md-switch>
  </div>
</template>

<script>
export default {
  name: 'InputNotificationChannels',

  props: {
    value: Object,
    name: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    model: null,
    selection: [],
    options: [
      {
        value: 'email',
        label: 'labels.email',
      },
      {
        value: 'mobilepush',
        label: 'labels.mobilepush',
      },
    ],
  }),

  mounted() {
    this.model = {
      notificationChannels: [],
      ...this.value,
    }

    this.selection = this.model.notificationChannels
  },

  watch: {
    value: {
      deep: true,
      handler() {
        this.model = {
          ...this.model,
          ...this.value,
        }
      },
    },

    selection() {
      this.model.notificationChannels = this.selection
      this.$emit('input', this.model)
    },
  },
}
</script>

<template>
  <div class="app-quick-nav">
    <div class="md-layout">
      <div class="md-layout-item">
        <div class="breadcrumbs">
          <span v-for="(link, index) in $store.state.breadcrumbs" :key="index">
            <span v-if="index < $store.state.breadcrumbs.length - 1">
              <router-link v-if="link.to" :to="link.to">
                {{ link.title }}</router-link
              >
              <span v-else>{{ link.title }}</span>
              <span class="separator">/</span>
            </span>
            <span v-else>
              {{ link.title || '...' }}
            </span>
          </span>
        </div>
      </div>
      <div
        v-if="$store.state.breadcrumbs.length > 1"
        class="md-layout-item md-size-25 md-small-size-35 md-xsmall-size-100"
      >
        <md-field v-if="places.length > 0">
          <label for="nav">{{ $t('actions.goTo') }}</label>
          <md-select
            name="nav"
            id="nav"
            md-dense
            v-model="goto"
            @md-closed="openGoto"
          >
            <template v-for="place in places">
              <md-option
                :key="'place' + place._id"
                :value="'place-' + place._id"
              >
                {{ place.title }}
              </md-option>
              <md-option
                v-for="point in place.points"
                class="child-option"
                :key="'point' + point._id"
                :value="'point-' + point._id"
              >
                {{ point.title }}
              </md-option>
            </template>
          </md-select>
        </md-field>
      </div>
    </div>
    <div v-if="$store.state.breadcrumbs.length > 1" class="button-back">
      <md-button class="md-icon-button md-medium-hide" @click="goBack">
        <md-icon>arrow_back</md-icon>
      </md-button>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  name: 'AppQuickNav',
  data: () => ({
    places: [],
    prevGoto: null,
    goto: null,
  }),

  created() {
    this.fetchData()
  },

  watch: {
    '$store.state.entity': {
      handler() {
        this.fetchData()
      },
    },
  },

  methods: {
    async fetchData() {
      if (this.$store.state.entity._id) {
        const [err, places] = await api
          .get('places', {
            query: { entity: this.$store.state.entity._id },
          })
          .send()
        if (err) return
        this.places = places
      }
    },

    goBack() {
      this.$router.go(-1)
    },

    openGoto() {
      if (this.prevGoto !== this.goto) {
        this.prevGoto = this.goto
        let split = this.goto.split('-')
        this.$router.replace({ name: split[0], params: { id: split[1] } })
        this.goto = null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.app-quick-nav {
  .md-field {
    margin: 0;
  }

  .breadcrumbs {
    min-height: 48px;
    margin-top: 30px;
    text-transform: uppercase;
    font-weight: 700;

    a,
    a:hover {
      color: #666;
    }
  }

  .button-back {
    position: fixed;
    top: 83px;
    left: 10px;
  }

  .separator {
    margin: 0 6px;
  }
}
</style>

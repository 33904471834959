<template>
  <div>
    <div class="padding-top">
      <AppList
        :opts="opts"
        :formOpts="formOpts"
        :items="items"
        @formSuccess="formSuccess"
      />
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import api from '@/api'

export default {
  name: 'PageResellerUsers',

  data: () => ({
    items: [],
    opts: {
      titleKey: 'navs.resellers',
      subtitleKey: 'navs.userManagement',
      createTextKey: 'actions.addNewUser',
      columns: [
        {
          name: 'lastName',
        },
        {
          name: 'firstName',
        },
        {
          name: 'email',
        },
        {
          name: 'role',
          type: 'component',
          component: 'AppListUserRole',
        },
      ],
    },
    formOpts: {
      titleKey: 'navs.user',
      endpoint: 'users',
      inputs: {
        firstName: {
          title: 'Vorname',
          type: 'text',
          validation: {
            required,
          },
        },
        lastName: {
          title: 'Nachname',
          type: 'text',
          validation: {
            required,
          },
        },
        email: {
          title: 'E-Mail',
          type: 'text',
          validation: {
            required,
            email,
          },
        },
        reseller: {
          title: 'Reseller',
          type: 'component',
          component: 'InputReseller',
        },
        language: {
          type: 'component',
          component: 'InputLanguage',
          validation: {
            required,
          },
        },
      },
      rows: [
        ['firstName', 'lastName'],
        ['email', 'language'],
      ],
    },
  }),

  mounted() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      const id = this.$route.params.id

      this.formOpts.defValues = {
        reseller: id,
        role: 'reseller',
      }

      const [err, users] = await api
        .get('users', { query: { reseller: id } })
        .send()
      if (err) return

      this.items = users
    },

    formSuccess() {
      this.fetchData()
    },
  },
}
</script>

<template>
  <div>
    <div class="padding-top">
      <md-card>
        <md-card-header>
          <h1 class="md-title">{{ $t('navs.parameters') }}</h1>
          <h2 class="md-caption">
            {{ $t('misc.manageParametersAndDetermineOrder') }}
          </h2>
        </md-card-header>

        <md-divider />

        <md-empty-state
          v-if="items && items.length === 0"
          md-icon="help_outline"
          :md-label="$t('states.noResults')"
        >
        </md-empty-state>

        <vue-draggable
          tag="md-list"
          class="md-double-line"
          v-model="items"
          handle=".handle"
          v-bind="{ draggable: '.item', ghostClass: 'sortable-ghost' }"
          @end="draggingEnded"
        >
          <md-list-item v-for="item in items" :key="item.id" class="item">
            <md-icon class="handle">drag_handle</md-icon>
            <div class="md-list-item-text">
              <span @click="openForm(item)" class="md-body-1">
                {{ item.title }}
                <span class="muted">
                  - {{ item.titleShort }}
                  <!-- <span v-if="item.unit">
                    - {{item.unit}}
                </span> -->
                </span>
              </span>
              <!-- <span>{{item.type}}  -->
              <!-- <span v-if="item.unit">
                {{item.unit}}
                <template v-if="item.range && item.range.enabled"> - Wertebereich aktiv</template>
              </span> -->

              <!-- </span> -->
            </div>
            <span v-if="item.valueSource === ParameterValueSource.Manual">
              <md-icon>touch_app</md-icon> {{ $t('labels.manual') }}
            </span>
            <span v-else-if="item.valueSource === ParameterValueSource.Lilian">
              <md-icon>settings_remote</md-icon> Lilian
            </span>
            <span v-else> <md-icon>superscript</md-icon> Calc </span>
            <md-button
              class="md-icon-button md-list-action md-primary"
              @click="openForm(item)"
            >
              <md-icon>edit</md-icon>
            </md-button>
          </md-list-item>
        </vue-draggable>
      </md-card>

      <md-button
        class="md-fab md-fab-bottom-right md-fixed md-accent"
        @click="openCreateForm"
      >
        <md-icon>add</md-icon>
      </md-button>
    </div>

    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
      <AppFormDialog
        :opts="formOpts"
        :values="formValues"
        @close="showDialog = false"
        @success="formSuccess"
      />
    </md-dialog>

    <md-snackbar
      :md-active.sync="showSnackbar"
      :md-duration="2000"
      md-persistent
    >
      <span>{{ $t('states.changesSavedSuccessfully') }}</span>
      <md-button class="md-primary" @click="showSnackbar = false">{{
        $t('actions.close')
      }}</md-button>
    </md-snackbar>
  </div>
</template>

<script>
import api from '@/api'

import { ParameterValueSource } from '@/commons/parameter.const'

const arrayMove = (arr, old_index, new_index) => {
  while (old_index < 0) {
    old_index += arr.length
  }
  while (new_index < 0) {
    new_index += arr.length
  }
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0])
  return arr
}

export default {
  name: 'PageParameters',

  data() {
    return {
      ParameterValueSource,
      items: null,
      order: null,
      showDialog: false,
      showSnackbar: false,
      formValues: {},
      formOpts: {
        endpoint: 'parameters',
        titleKey: 'navs.parameter',
        inputs: {
          parameter: {
            type: 'component',
            component: 'InputParameter',
          },
        },
        rows: [['parameter']],
      },
    }
  },

  created() {
    this.formOpts.defValues = {
      manual: false,
      range: { enabled: false },
    }
  },

  mounted() {
    this.fetchData()
  },

  methods: {
    draggingEnded(e) {
      const { oldIndex, newIndex } = e
      let order = this.order
      this.order = arrayMove(order, oldIndex, newIndex)
      api.put('parameters/order', { body: { order } }).send()
    },

    openForm(item) {
      this.formOpts.isCreate = false
      this.formValues = item
      this.showDialog = true
    },

    openCreateForm() {
      this.formOpts.isCreate = true
      this.formValues = {
        range: {
          enabled: false,
          lowest: 1,
          low: 2,
          basis: 3,
          high: 4,
          highest: 5,
        },
        options: [],
      }
      this.showDialog = true
    },

    formSuccess() {
      this.fetchData()
    },

    async fetchData() {
      const [err, parameters] = await api.get('parameters').send()
      if (err) return
      let order = []
      parameters.map((p) => {
        order.push(p._id)
      })
      this.order = order
      this.items = parameters
    },
  },
}
</script>

<style lang="scss" scoped>
.item {
  padding: 0 20px 0 10px;
}
.md-list-item-text:hover {
  cursor: pointer;
}
.handle {
  cursor: move;
}
.sortable-ghost {
  height: 72px;
  background-color: #eee;
}
.sortable-drag {
  height: 72px;
  background-color: #ccc;
}
</style>

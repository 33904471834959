<template>
  <div>
    <div class="padding-top">
      <AppList
        :opts="opts"
        :formOpts="formOpts"
        :items="items"
        @formSuccess="formSuccess"
      />
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import api from '@/api'

export default {
  name: 'PageResellers',

  data: () => ({
    items: [],
    opts: {
      titleKey: 'navs.resellers',
      subtitleKey: 'misc.manageResellers',
      searchName: 'title',
      columns: [
        {
          name: 'title',
          label: 'labels.reseller',
          type: 'edit',
        },
        {
          name: 'switchClient',
          type: 'component',
          component: 'AppListShowResellerUsers',
        },
      ],
    },
    formOpts: {
      titleKey: 'labels.reseller',
      endpoint: 'resellers',
      inputs: {
        title: {
          type: 'text',
          validation: {
            required,
          },
        },
      },
      rows: [['title']],
    },
  }),

  mounted() {
    this.formSuccess()
  },

  methods: {
    async formSuccess() {
      const [err, clients] = await api.get('resellers').send()
      if (err) return
      this.items = clients
    },
  },
}
</script>

module.exports = {
  apiBaseUrl: process.env.VUE_APP_API_BASE_URL,

  refreshAlertsIntervalInSeconds: 10,

  // TODO: can we get those from the configs within the API?
  supportedLocales: [
    'en',
    'de',
    'es',
    'sv',
    'it',
    'fr',
    'nl',
    'fi',
    'no',
    'dk',
  ],

  fallbackLocale: 'en',

  /**
   * Display when value is under range.
   *
   * Required for UR/OR test during XLSX export.
   */
  underRangeLabel: 'UR',

  /**
   * Display when value is over range.
   *
   * Required for UR/OR test during XLSX export.
   */
  overRangeLabel: 'OR',
}

<template>
  <div>
    <div class="body-1">{{ $t('labels.pointParameters') }}</div>
    <div v-if="loading">
      {{ $t('states.loading') }}
    </div>
    <div v-else-if="pointParameterConfigs && parameters">
      <div class="muted" v-if="noParameterExist">
        - {{ $t('states.noResults') }} -
      </div>
      <div v-for="parameter in parameterList" :key="parameter.uniqueName">
        <md-switch
          class="md-primary"
          v-model="pointParameterConfigs[parameter.uniqueName].enabled"
          :disabled="parameter.disabled || !parameter.requirementsAvailable"
        >
          <span class="bold">{{ parameter.title }}</span>
        </md-switch>
        <div v-if="!parameter.requirementsAvailable" class="ml-4 mb-2">
          <span>
            <md-icon>info</md-icon>
            {{ $t('labels.requiredParameters') }}:
            {{ parameter.calculationRequirementTitles.join(', ') }}
          </span>
        </div>
        <div
          v-if="pointParameterConfigs[parameter.uniqueName].enabled"
          class="ml-4 mr-2"
        >
          <div
            class="mb-1"
            v-if="
              parameterTypesIndex[parameter.type].configs.offsetEnabled &&
              pointParameterConfigs[parameter.type]
            "
          >
            <InputParameterOffset
              v-model="pointParameterConfigs[parameter.uniqueName]"
              :payload="{
                ...payload,
                ...parameterTypesIndex[parameter.type].configs,
              }"
            />
          </div>
          <div
            class="mb-2"
            v-if="parameterTypesIndex[parameter.type].configs.rangeEnabled"
          >
            <InputParameterRange
              v-model="pointParameterConfigs[parameter.uniqueName]"
              :payload="payload"
            />
          </div>
        </div>
        <md-divider />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import api from '@/api'
import auth from '@/auth'
import { ParameterValueSource } from '@/commons/parameter.const'

export default {
  name: 'InputPointParameters',

  props: {
    value: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    payload: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    model: null,
    loading: true,
    parameters: null,
    localizedMainConfigs: undefined,
    pointParameterConfigs: null,
    noParameterExist: false,
    parameterTypesIndex: undefined,
    parameterList: [],
  }),

  watch: {
    pointParameterConfigs: {
      deep: true,
      handler(val) {
        this.model.parameterConfigs = val
        this.$emit('input', this.model)
        this.buildParameterList()
      },
    },
  },

  methods: {
    checkCalculationRequirements(parameter) {
      if (parameter.valueSource === ParameterValueSource.Calculation) {
        let requirementsAvailable = true
        if (parameter.calculationRequirements) {
          parameter.calculationRequirements.map((requiredUniqueName) => {
            if (
              !this.pointParameterConfigs[requiredUniqueName] ||
              !this.pointParameterConfigs[requiredUniqueName].enabled
            )
              requirementsAvailable = false
          })
        }

        return requirementsAvailable
      }

      return true
    },

    buildParameterList() {
      const list = []

      this.parameters.map((p) => {
        const calculationRequirementTitles = []
        if (p.calculationRequirements) {
          p.calculationRequirements.map((uniqueName) => {
            calculationRequirementTitles.push(
              this.parameterTypesIndex[uniqueName].configs.title,
            )
          })
        }

        list.push({
          type: p.type,
          title: p.title,
          uniqueName: p.uniqueName,
          requirementsAvailable: this.checkCalculationRequirements(p),
          calculationRequirementTitles,
        })
      })

      this.parameterList = list
    },
  },

  async mounted() {
    // TODO: work with the parameter helper here and remove logic from template.

    this.model = { ...this.value }
    this.loading = true

    const localizedMainConfigs = auth.getLocalizedMainConfigs()

    const systemParameterTypesIndex = _.keyBy(
      localizedMainConfigs.systemParameterTypes,
      'name',
    )
    const legacySystemParameterTypesIndex = _.keyBy(
      localizedMainConfigs.legacySystemParameterTypes,
      'name',
    )
    const customParameterTypesIndex = _.keyBy(
      localizedMainConfigs.customParameterTypes,
      'name',
    )
    this.parameterTypesIndex = {
      ...systemParameterTypesIndex,
      ...legacySystemParameterTypesIndex,
      ...customParameterTypesIndex,
    }

    this.localizedMainConfigs = localizedMainConfigs
    const pointParameterConfigs = {}

    const [err, parameters] = await api.get('parameters').send()

    if (err) return
    if (parameters.length === 0) {
      this.noParameterExist = true
    } else {
      // Build the pointParameterConfigs object
      parameters.map((p) => {
        pointParameterConfigs[p.uniqueName] = {
          // Disable by default
          enabled: false,
          ...(this.model.parameterConfigs
            ? this.model.parameterConfigs[p.uniqueName]
            : {}),
        }
      })
      this.parameters = parameters
      this.pointParameterConfigs = pointParameterConfigs
      this.loading = false

      this.buildParameterList()
    }
  },
}
</script>

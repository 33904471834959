<template>
  <div class="mt-4 mb-4">
    <md-card>
      <md-card-header>
        <div class="md-layout">
          <div class="md-layout-item">
            <div class="header">
              <h1 class="md-title">{{ $t('navs.measurements') }}</h1>
            </div>
          </div>
        </div>

        <div class="md-layout md-gutter">
          <div class="md-layout-item md-xsmall-size-100 md-small-size-50">
            <md-field>
              <label for="range">{{ $t('labels.range') }}</label>
              <md-select v-model="range" name="range" id="range">
                <md-option value="today">
                  {{ $t('labels.today') }}
                </md-option>
                <md-option value="yesterday">
                  {{ $t('labels.yesterday') }}
                </md-option>
                <md-option value="daterange">
                  {{ $t('labels.customDateRange') }}
                </md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-xsmall-size-100 md-small-size-20">
            <md-datepicker
              v-if="range === 'daterange'"
              v-model="dateStart"
              :md-immediately="true"
              :md-close-on-blur="false"
            >
              <label>{{ $t('labels.from') }}</label>
            </md-datepicker>
          </div>
          <div class="md-layout-item md-xsmall-size-100 md-small-size-20">
            <md-datepicker
              v-if="range === 'daterange'"
              v-model="dateEnd"
              :md-immediately="true"
              :md-close-on-blur="false"
            >
              <label>{{ $t('labels.to') }}</label>
            </md-datepicker>
          </div>
          <div class="md-layout-item md-xsmall-size-100 md-small-size-10 ta-r">
            <div class="mt-2">
              <md-button @click="updateMeasurements" class="md-primary">
                <md-icon>refresh</md-icon>
              </md-button>
            </div>
          </div>
        </div>
      </md-card-header>

      <md-divider />

      <md-empty-state
        v-if="items.length === 0"
        md-icon="help_outline"
        :md-description="$t('states.noResults')"
      >
      </md-empty-state>

      <div v-else>
        <div v-for="point in items" :key="point._id">
          <h2 class="md-body-1 ml-3 mr-3 mt-3">
            {{ point.place.entity.title }} / {{ point.place.title }} /
            {{ point.title }}
          </h2>
          <MeasurementsList
            v-if="point.measurements.length"
            :hideShowFlaggedSwitch="true"
            :pointData="point"
          />
          <div v-else class="muted ml-3 mr-3 mb-3">
            {{ $t('states.noResults') }}
          </div>
          <md-divider />
        </div>
      </div>
    </md-card>
  </div>
</template>

<script>
import moment from 'moment'
import api from '@/api'

const getFilter = (vm = {}) => {
  // Default is 'today'
  let dateStart
  let dateEnd
  const range = vm.range || 'today'
  if (range === 'daterange' && vm.dateStart && vm.dateEnd) {
    dateStart = moment(vm.dateStart).startOf('day').format()
    dateEnd = moment(vm.dateEnd).endOf('day').format()
  } else if (range === 'yesterday') {
    dateStart = moment().subtract(1, 'day').startOf('day').format()
    dateEnd = moment().subtract(1, 'day').endOf('day').format()
  } else if (range === 'today') {
    dateStart = moment().startOf('day').format()
    dateEnd = moment().endOf('day').format()
  }
  return { dateStart, dateEnd }
}

export default {
  name: 'PageMeasurements',

  data: () => ({
    items: [],
    range: 'today',
    dateStart: moment().subtract(1, 'day').startOf('day').toDate(),
    dateEnd: moment().endOf('day').toDate(),
    layout: 'list',
    prevGoto: null,
    goto: null,
    pointData: {
      place: {},
      parameters: {},
      measurements: {},
    },
  }),

  mounted() {
    this.fetchData()
  },

  watch: {
    range: function () {
      this.updateMeasurements()
    },

    dateStart: function () {
      this.updateMeasurements()
    },

    dateEnd: function () {
      this.updateMeasurements()
    },
  },

  methods: {
    updateMeasurements() {
      this.fetchData()
    },

    async fetchData() {
      const [err, data] = await api
        .get('points', { query: getFilter(this) })
        .send()
      if (err) return
      this.items = data
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  margin: 0 0 20px;
}
</style>

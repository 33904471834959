<template>
  <div>
    <div class="padding-top">
      <AppList
        :opts="opts"
        :formOpts="formOpts"
        :items="items"
        @formSuccess="formSuccess"
      />
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import api from '@/api'

export default {
  name: 'PageUsers',

  data: () => ({
    items: [],
    opts: {
      titleKey: 'navs.userManagement',
      subtitleKey: 'misc.manageUserAccess',
      editRouteName: 'user',
      createRouteName: 'user',
      searchName: 'lastName',
      createTextKey: 'actions.addNewUser',
      columns: [
        {
          name: 'lastName',
          type: 'link',
          link: {
            name: 'user',
            idPath: '_id',
          },
        },
        {
          name: 'firstName',
        },
        {
          name: 'email',
        },
        {
          name: 'role',
          type: 'component',
          component: 'AppListUserRole',
        },
      ],
    },
  }),

  mounted() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      const [err, users] = await api.get('users').send()
      if (err) return
      this.items = users
    },

    formSuccess() {
      this.fetchData()
    },
  },

  computed: {
    formOpts() {
      return {
        titleKey: 'navs.user',
        endpoint: 'users',
        inputs: {
          firstName: {
            title: 'Vorname',
            type: 'text',
            validation: {
              required,
            },
          },
          lastName: {
            title: 'Nachname',
            type: 'text',
            validation: {
              required,
            },
          },
          email: {
            title: 'E-Mail',
            type: 'text',
            validation: {
              required,
              email,
            },
          },
          role: {
            title: 'Rolle',
            type: 'select',
            options: {
              admin: this.$t('labels.admin'),
              employee: this.$t('labels.employee'),
            },
            validation: {
              required,
            },
          },
          language: {
            type: 'component',
            component: 'InputLanguage',
            validation: {
              required,
            },
          },
          companyName: {
            title: 'Firma',
            type: 'text',
          },
          mobilePhone: {
            title: 'Handynummer',
            type: 'text',
          },
          phone: {
            title: 'Telefonnummer',
            type: 'text',
          },
          notificationChannels: {
            type: 'component',
            component: 'InputNotificationChannels',
          },
          permittedEntities: {
            type: 'component',
            component: 'InputEntities',
          },
        },
        rows: [
          ['firstName', 'lastName'],
          ['role', 'language'],
          ['email', 'companyName'],
          ['mobilePhone', 'phone'],
          ['notificationChannels'],
          ['permittedEntities'],
        ],
        defValues: {
          notificationChannels: [],
          permittedEntities: [],
        },
      }
    },
  },
}
</script>

<style scoped></style>
